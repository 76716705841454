import React from "react";
import BillsAddButtons from "./BillsAddButtons";
import BillDetailsFooter from "./BillDetailsFooter";

const BillDetailsContainer = ({ Owl }) => {
    return (
        <div className="details-inner-container">
            <BillsAddButtons Owl={Owl} />
            <div
                ref={(_ref) => {
                    global.billContainer = _ref;
                }}
                id="bill-container"
                className="col gap-3 overflow-auto scroller"
            />
            <BillDetailsFooter Owl={Owl} />
        </div>
    );
};

export default React.memo(BillDetailsContainer);
