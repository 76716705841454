import React from "react";
import FormTest from "./FormTest";

const AddAccount = ({ accountName, sendBill }) => {
    const data = {
        name: { value: accountName, required: true },
        phone: { value: "", required: false },
        address: { value: "", required: false },
    };
    const onSubmit = (_data) => {
        if (!_data) return global.setPopup(null);
        let account = {
            name: _data.name.value,
            phone: _data.phone.value,
            address: _data.address.value,
            id: 0,
        };
        global.billItemChanged(account, "UpdateAccount");
        global.setPopup(null);
        sendBill && document.getElementById("send-bill-button").click();
    };
    return <FormTest data={data} onSubmit={onSubmit} />;
};

export default AddAccount;
