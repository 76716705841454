import React from "react";

const SplitContainer = ({ ResizebleObj, Owl, children, className }) => {
    ResizebleObj.tableRef = React.useRef(null);
    return (
        <div onBlur={Owl.onBlur} onFocus={Owl.onFocus} ref={ResizebleObj.tableRef} style={{ gridTemplateColumns: ResizebleObj.style }} className={className}>
            {children}
        </div>
    );
};

export default SplitContainer;
