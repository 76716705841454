import React from "react";
import Language from "../Sale/Utils/Language";

const NoInternet = ({}) => {
    return (
        <div
            onClick={({ target }) => {
                window.location.reload();
            }}
            className="fixed p-10 inset-0 z-50 row justify-center blur-bg overflow-auto">
            <div className="col-center col-span-full" style={{ maxWidth: 720 }}>
                <p className="text-white text-center animate-bounce rounded-lg font-bold " style={{ padding: "40px 30px", backgroundColor: "#ee6363" }}>
                    {Language.NoInternet} !
                </p>
            </div>
        </div>
    );
};

export default NoInternet;
