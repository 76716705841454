const InputText = ({ value, placeholder, setValue, type = "text", className, step, required }) => (
    <input
        type={type}
        step={step}
        required={required}
        className={"owl-input " + className}
        value={value}
        placeholder={placeholder}
        name={placeholder}
        onChange={({ target }) => setValue(target.value)}
    />
);
export default InputText;
