import React, { Fragment } from "react";
import AddAccount from "../Sale/Components/AddAccount";
import Utils from "../Sale/Utils/Utils";

const SearchOptionsGridNodeContainer = ({ Owl, ItemBuilder, className, searchOptionsChange, defaultValue }) => {
    console.debug("Items Node Container RD");
    [Owl.toggled, Owl.setToggle] = React.useState(false);
    [Owl.newAccount, Owl.setNewAccount] = React.useState("");
    Owl.itemsRef = React.useRef(null);
    React.useEffect(() => {
        if (Owl.setItems) return;
        setTimeout(() => {
            Owl.setItems = (items, clear) => {
                if (clear) {
                    let query = Owl.queryParams?.query?.value;
                    if (query) {
                        if (query !== defaultValue) Owl.setNewAccount(query);
                    }
                    items.splice(0, 0, defaultValue);
                    Owl.items = [];
                    Utils.removeAllChildNodes(Owl.itemsRef.current);
                }
                let i = Owl.items;
                items.map((item) => {
                    Owl.itemsRef.current.append(ItemBuilder({ item, i }));
                    Owl.items.push(item);
                    i++;
                });
            };
        }, 10);
    }, []);

    React.useEffect(() => {
        if (Owl.toggled) {
            if (Owl.items.length < 1) Owl.search();
            Owl.items.map((item, i) => {
                Owl.itemsRef.current.appendChild(ItemBuilder({ item, i }));
            });
        }
    }, [Owl.toggled]);

    function onClick({ target }) {
        let id = target.id;
        try {
            id = parseInt(id);
        } catch (e) {}

        let item = Owl.items.find((o) => o.id === id);
        if (item) {
            Owl.setToggle(false);
            searchOptionsChange(item);
        }
    }

    return Owl.toggled ? (
        <div onScroll={Owl.onScroll} className={className}>
            {Owl.newAccount && (
                <p
                    id="search-new-account"
                    onClick={() => {
                        console.log("new account");
                        global.setPopup({ page: <AddAccount accountName={Owl.newAccount} Owl={Owl} sendBill={false} /> });
                    }}
                    className="owl-button">
                    + "{Owl.newAccount}"
                </p>
            )}
            <div id="list-parent" ref={Owl.itemsRef} onClick={onClick} />
        </div>
    ) : (
        <Fragment />
    );
};

export default SearchOptionsGridNodeContainer;
