import React from "react";

const Loading = ({ data }) => {
    return <div className="owl-loading " />;

    return (
        <div style={{ height: "60vh" }}>
            <div className="lds-ripple">
                <div></div>
                <div></div>
            </div>
        </div>
    );
};

export default Loading;
