import { handleClosePoup, SetKeyboardTypeMethod } from "./KeysLayout";
import "./popup.css";

let KyeboardNode = null;

let keyboardProps = {
    width: 200,
    height: 250,
    backgroundColor: "white",
    type: "text",
    lang: "ar",
    UpperCase: false,
    inputRefProps: null,
};

const GetkeyBorad = (e) => {
    const inputRef = e.target;
    if (inputRef.nodeName !== "INPUT") return;
    let inputType = inputRef.attributes.type?.value || "text";
    // inputRef.step = "any";

    // var nativeInputValueSetter = Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, "value").set;
    // nativeInputValueSetter.call(inputRef, "react 16 value");

    // var ev2 = new Event("input", { bubbles: true });
    // inputRef.dispatchEvent(ev2);
    if (!KyeboardNode) setupKeyboardNode();

    if (keyboardProps.type !== inputType) {
        keyboardProps.type = inputType;
        SetKeyboardTypeMethod[keyboardProps.type](keyboardProps, KyeboardNode);
    }

    if (inputType === "number" && KyeboardNode?.childNodes[14]) KyeboardNode.childNodes[14].remove();

    let { x, y, width, height } = inputRef.getBoundingClientRect();
    keyboardProps.inputRefProps = { x, y, height, width };

    setKeyboardPostion();
    setupKeyboardClick(inputRef, inputType);
    document.getElementById("root").append(KyeboardNode);
    handleClosePoup(inputRef, KyeboardNode);
};

export default GetkeyBorad;

function setupKeyboardNode() {
    KyeboardNode = document.createElement("div");
    KyeboardNode.id = "popupKeybord";
    SetKeyboardTypeMethod[keyboardProps.type](keyboardProps, KyeboardNode);
}

function setKeyboardPostion() {
    let topCorrecter =
        keyboardProps.inputRefProps.y > window.innerHeight / 1.5 //
            ? -keyboardProps.height
            : keyboardProps.inputRefProps.height + 10;

    let leftCorrecter =
        keyboardProps.inputRefProps.x > window.innerWidth / 2 //
            ? -keyboardProps.width + keyboardProps.inputRefProps.width
            : 0;

    KyeboardNode.style.top = `${keyboardProps.inputRefProps.y + topCorrecter}px`;
    KyeboardNode.style.left = `${keyboardProps.inputRefProps.x + leftCorrecter}px`;
}

function setupKeyboardClick(inputRef, inputType) {
    var ev2 = new Event("input", { bubbles: true });
    var nativeInputValueSetter = Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, "value").set;
    function setInputValue(value) {
        if (inputRef.maxLength > 0 && value.length > inputRef.maxLength) return;
        nativeInputValueSetter.call(inputRef, value);
        inputRef.dispatchEvent(ev2);
    }
    if (keyboardProps.type !== "number")
        KyeboardNode.onclick = (e) => {
            if (e.target.id === "popupKeybord") return;
            let method = keysMethods[e.target.innerText];
            if (method) method(inputRef, setInputValue);
            else setInputValue(inputRef.value + e.target.innerText);
        };
    else
        KyeboardNode.onclick = (e) => {
            if (e.target.id === "popupKeybord") return;
            let method = keysMethods[e.target.innerText];
            if (method) method(inputRef, setInputValue);
            else {
                if (inputRef.value.startsWith(".")) {
                    setInputValue(inputRef.value.slice(1) + "." + e.target.innerText);
                } else setInputValue(inputRef.value + e.target.innerText);
            }
        };
}

const OneCase = ["Caps", "Shift", "Backspace", "Enter", "Space", "Close", "Clear", "Num", "ABC"];

const keysMethods = {
    ".": (inputRef, setInputValue) => {
        if (inputRef.value.indexOf(".") === -1) {
            setInputValue(inputRef.value.length === 0 ? ".0" : "." + inputRef.value);
        }
    },
    0: (inputRef, setInputValue) => {
        if (keyboardProps.type === "number" && inputRef.value.length < 1) return;
        setInputValue(inputRef.value + 0);
    },
    "⌫": (inputRef, setInputValue) => {
        setInputValue(inputRef.value.slice(0, -1));
    },
    "⌦": (inputRef, setInputValue) => {
        setInputValue(inputRef.value.slice(0, -1));
    },
    "↩": (inputRef) => {
        inputRef.blur();
    },
    Clear: (inputRef, setInputValue) => {
        setInputValue("");
    },
    "🌐": () => {
        if (keyboardProps.lang === "en") {
            keyboardProps.lang = "ar";
            SetKeyboardTypeMethod[keyboardProps.type](keyboardProps, KyeboardNode);
        } else {
            keyboardProps.lang = "en";
            SetKeyboardTypeMethod[keyboardProps.type](keyboardProps, KyeboardNode);
        }
    },
    Caps: () => {
        if (keyboardProps.lang !== "en") return;
        if (keyboardProps.UpperCase)
            KyeboardNode.childNodes.forEach((key) => {
                if (!OneCase.includes(key.innerText)) key.innerText = key.innerText.toLowerCase();
            });
        else
            KyeboardNode.childNodes.forEach((key) => {
                if (!OneCase.includes(key.innerText)) key.innerText = key.innerText.toUpperCase();
            });
        keyboardProps.UpperCase = !keyboardProps.UpperCase;
    },
    Num: () => {
        keyboardProps.type = "number";
        SetKeyboardTypeMethod[keyboardProps.type](keyboardProps, KyeboardNode);
        setKeyboardPostion();
    },
    ABC: () => {
        keyboardProps.type = "text";
        SetKeyboardTypeMethod[keyboardProps.type](keyboardProps, KyeboardNode);
        setKeyboardPostion();
    },
};
