import React from "react";
import { memo } from "react";
import Language from "../Utils/Language";

const InputData = memo(({ data, className = "owl-input" }) =>
    Object.entries(data).map(([key, value]) => <Input className={className} id={key} key={key} value={value} />)
);
export default InputData;

export const Input = memo(({ id, value, type = "text", className }) => (
    <>
        <input
            onChange={({ target }) => {
                value.value = target.value;
            }}
            id={id}
            type={type}
            placeholder={Language[id]}
            className={className}
            defaultValue={value.value}
            style={{ width: 300, marginRight: -5 }}
        />
        <span />
    </>
));
