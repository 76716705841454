import React from "react";
import Info from "../../Nodes/Info";
import Confirmer from "../Components/Confirmer";
import RadioButton from "../Components/RadioButton";
import { API } from "../SalePointAPI";
import { SettingsOptions } from "../Utils/Consts";
import Language from "../Utils/Language";
import PrintSettingsRoute from "./PrintSettingsRoute";

const Settings = ({ Owl, storedSettings }) => {
    React.useEffect(() => {
        if (!Owl.closePopup) {
            Owl.closePopup = close;
        }
    }, []);

    const close = () => {
        let reload = false;
        Object.entries(SettingsOptions).forEach(([key, val]) => {
            if (storedSettings[key] !== val.value) {
                if (key === "TheCurrency") {
                    clearCash();
                    clearBills();
                } else if (key === "ShowNotificationOnSaleInNegative") return;
                reload = true;
            }
        });

        Owl.closePopup = null;
        reload && window.location.reload();
    };
    return (
        <div className="p-4 bg-white rounded-lg col">
            <div className="row-center justify-between p-3">
                <h3 className="font-bold">{Language.Settings}</h3>
                <p onClick={close} id="close-popup-icon" className="close-icon font-bold text-red-400">
                    x
                </p>
            </div>
            <hr />

            {Object.entries(SettingsOptions).map(([key, options]) => (
                <RadioButton key={key} options={options} />
            ))}

            <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }} className="gap-4 text-center border-top">
                <span
                    id="close-popup-print-last-bill"
                    onClick={() => {
                        if (localStorage.getItem("print-data")) window.open("/print", "PRINT", "height=auto,width=auto");
                        close();
                    }}
                    className="this-button-2">
                    {Language.PrintLastBill}
                </span>
                <span
                    onClick={() => {
                        let oldPopup = global.popup;
                        global.setPopup({
                            page: <Confirmer onAgreed={clearCash} title="ClearCashMessage" onDisagreed={() => global.setPopup(oldPopup)} />,
                        });
                    }}
                    className="this-button-2">
                    {Language.ClearCash}
                </span>
                <span
                    onClick={() => {
                        global.setPopup({
                            page: <PrintSettingsRoute Owl={Owl} />,
                            close,
                        });
                    }}
                    className="this-button-2">
                    {Language.ReceiptSettings}
                </span>
                <span
                    onClick={async () => {
                        global.setPageState(["progressing"]);
                        const { data } = await API.get("printsettings");
                        Info({ title: "Updated", content: "", type: "success" });
                        global.setPageState(["none"]);
                        sessionStorage.setItem("banner", JSON.stringify(data.banner));
                        localStorage.setItem("logo", JSON.stringify(data.logo));
                    }}
                    className="this-button-2">
                    {Language.UpdateBannerAndLogo}
                </span>
            </div>
        </div>
    );
};

export default Settings;
function clearCash() {
    let allCash = JSON.parse(localStorage.getItem("all-cash-queries")) || [];
    allCash.concat(JSON.parse(localStorage.getItem("all-search-cash-queries")) || []);
    allCash.forEach((cashId) => {
        localStorage.removeItem(cashId);
    });
    window.location.reload();
}

function clearBills() {
    let billsIds = JSON.parse(localStorage.getItem("billsIds")) || {
        bills: [],
    };
    billsIds.bills.forEach((billId) => {
        localStorage.removeItem(billId);
    });
}
