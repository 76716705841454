import React from "react";
import Language from "../Utils/Language";
import InputData from "./InputData";

const FormTest = ({ data, onSubmit }) => {
    // const data = {
    //     name: { value: "TEST", required: true },
    //     password: { value: "", required: true },
    //     address: { value: "", required: true },
    //     phone: { value: "", required: true },
    //     email: { value: "", required: true },
    //     TEASTING: { value: "", required: true },
    //     fuck: { value: "", required: true },
    // };

    const submit = (e) => {
        e.preventDefault();
        let ok = true;
        Object.entries(data).forEach(([key, value]) => {
            console.log({ value });
            if (value.required && value.value === "") {
                const input = document.getElementById(key);
                input.classList.add("shake");
                const pastPlaceHolder = input.placeholder;
                input.placeholder = Language["Required"];
                setTimeout(() => {
                    input.value = value.value;
                    input.placeholder = pastPlaceHolder;
                    input.classList.remove("shake");
                }, 2000);
                ok = false;
            }
        });
        if (!ok) return false;
        onSubmit(data);
        return false;
    };

    return (
        <form onSubmit={submit} className="col gap-5 p-4 bg-white rounded shadow-lg" style={{ maxWidth: 500 }}>
            <p className="font-bold">{Language.AddAccountTitle}</p>
            <InputData data={data} />
            <div className="row-center justify-around">
                <input type="submit" className="owl-button" value={Language.Save} />
                <p onClick={() => onSubmit(false)} className="owl-button">
                    {Language.Cancel}
                </p>
            </div>
        </form>
    );
};

export default FormTest;
