import { Div, P } from "../Nodes/NodeBuilder";
import GetAddonsChild from "./AddonsProvider";
export default class SeparatedAddons {
    constructor({
        title,
        containerId,
        direction = "",
        buttonClass = "addon-button",
        cancelTitle = "الغاء",
        saveTitle = "إضافة",
        type = "String",
        options,
        onChange,
        value = [],
        inputProps = {},
        onAddClicked,
    }) {
        this.value = value;

        let addonParent;

        setDefaultPropsInput(inputProps, title);

        const addButton = P({
            className: buttonClass,
            onclick: () => {
                if (this.value !== null) cancelClicked();
                else addType();
                onAddClicked(this.value);
            },
            innerText: this.value ? cancelTitle : title,
            id: "addon-button",
        });

        this.parent = Div({ className: `addon-main ${direction}` }, [addButton]);
        const onLoad = () => {
            const container = document.getElementById(containerId);
            if (!container) return;
            addonParent = container.parentElement;
            container.insertBefore(this.parent, container.childNodes[2]);
        };

        document.readyState === "complete" || document.readyState === "interactive" ? onLoad() : document.addEventListener("DOMContentLoaded", onLoad);

        const GetChild = (prop) => {
            prop.onChange = (value) => {
                this.value = value;
                if (onChange && !onChange(this.value)) {
                    addon.classList.add("shake");
                    return false;
                }
                removeTheAddon();
                return true;
            };
            return GetAddonsChild(prop);
        };
        const addon = Div({ className: "addons-fade-in" }, [GetChild({ inputProps, type, saveTitle, options })]);

        const addType = () => {
            this.value = undefined;
            addon.replaceChild(GetChild({ inputProps, type, saveTitle, options }), addon.firstChild);
            addonParent.insertBefore(addon, addonParent.lastChild);
            addButton.innerText = cancelTitle;
        };

        const cancelClicked = () => {
            this.value = null;
            let _input = addon.querySelector("input");
            _input.value = null;
            _input.blur();
            addButton.innerText = title;
            onChange(this.value);
        };

        const removeTheAddon = () => {
            addButton.innerText = this.value ? cancelTitle : title;

            addon.classList.add("addons-fade-out");
            setTimeout(() => {
                addon.classList.remove("addons-fade-out");
                addon.remove();
            }, 200);
        };
        this.setValue = (value) => {
            this.value = value;
            addButton.innerText = this.value ? cancelTitle : title;
        };
    }
}

const setDefaultPropsInput = (inputProps, title) => {
    if (!inputProps.type) inputProps.type = "text";
    if (!inputProps.placeholder) inputProps.placeholder = title;
    if (!inputProps.className) inputProps.className = "addon-input";
};

// const= addChildAndCheckMaximum(prop) =>{
//     if (_addonsRef.childNodes) {
//         addBtnRef.nnerText = cancelTitle;
//     }
// }
