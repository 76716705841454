import React from "react";
import getResizebleTableObj from "../Utils/ResizebleMethods";
import PrintTable from "./PrintTable";
import "./print.css";

const splitCols = [0.6, 0.4];

const tableCols = [
    { key: "billQuantity", title: "الرقم", initialWidthFactor: 0.6 },
    { key: "name", title: "الاسم", initialWidthFactor: 0.2 },
    { key: "quantity", title: "العدد", initialWidthFactor: 0.2 },
];

const _spliter = {
    // cols: tableCols,
    containerClass: "owl-print-container",
    storageKey: "print-table-cols",
    tableCols,
    isTable: true,
    // widthToDisplay: 1000,
    className: "owl-print-table",
    showIndex: true,
    // minColWidth: 0.1,
};

const Owl = {
    ResizebleObj: {},
    spliter: {},
    items: [],
};

const OwlPrintContainer = ({ spliter = _spliter, items, children, footer, fontSize }) => {
    console.debug("OwlPrintContainer RD");
    React.useMemo(() => {
        // if (Owl.init) return;
        // Owl.init = true;
        Owl.onScroll = ({ target }) => {
            if (Owl.canLoadMore && target.scrollHeight - target.scrollTop < target.clientHeight + 100) {
                Owl.fetch();
            }
        };
        Owl.spliter = spliter;

        if (!Owl.spliter.className) Owl.spliter.className = "owl-print-table";
        getResizebleTableObj(Owl.ResizebleObj, Owl.spliter);
        Owl.items = items;
        // setTimeout(() => {
        //     Owl.setItems(items);
        // }, 10);
    }, []);

    return (
        <div id="owl-print-container" style={{ fontSize }} className={spliter.containerClass || "owl-print-container"}>
            {children}
            <PrintTable Owl={Owl} />
            {footer}
        </div>
    );
};

export default OwlPrintContainer;
