import React from "react";
import "../css/owl.css";
import "../css/others.css";
import "../css/owlContainers.css";
import "../Addons/addons.css";
import "../Nodes/nodes.css";
import { BrowserRouter as Router, Link, Route, Routes } from "react-router-dom";
import SalePointView from "../Sale/SalePointView";
import PrintRoute from "../Sale/Routs/PrintRoute";
import Settings from "../Sale/Routs/Settings";
import RedirectRoute from "../Sale/Routs/RedirectRoute";
import LoginRoute from "../Sale/Routs/LoginRoute";

const Root = () => {
    console.debug("Root RD");

    return (
        <Router>
            <Routes>
                {!localStorage.getItem("logged-user") ? (
                    <Route path="/login" element={<LoginRoute />} />
                ) : (
                    <>
                        <Route path="/" element={<SalePointView />} />
                        <Route path="/print" element={<PrintRoute />} />
                        <Route path="/settings" element={<Settings />} />
                        {/* <Route path="/settings/print" element={<PrintSettingsRoute />} /> */}
                    </>
                )}
                <Route path="*" element={<RedirectRoute />} />
            </Routes>
        </Router>
    );
};

export default Root;

const links = () => (
    <div className="bg-white row p-1 gap-4 justify-center z-50 shadow-gray relative">
        <Link className="link bg-white px-3 p-1 font-bold mx-1 rounded shadow-1" to="/">
            Setup
        </Link>
        <Link className="link bg-white px-3 p-1 font-bold mx-1 rounded shadow-1" to="/sale">
            saleer
        </Link>
        {/* <Link className="link bg-white px-3 p-1 font-bold mx-1 rounded shadow-1" to="/result">
                    Result
                </Link> */}

        {/* {Bodies} */}
    </div>
);
