const String = ({ valueChanged, cancelClicked, id, cancelTitle, placeholder = "النص", inputClass, value = "" }) => {
    const container = document.createElement("div");
    container.className = "addon-input-container";
    container.id = id;

    const input = document.createElement("input");
    input.className = inputClass;
    input.defaultValue = value;

    input.placeholder = placeholder;
    input.onchange = function () {
        valueChanged(parseInt(container.id), this.value);
    };
    input.onkeydown = function (e) {
        if (e.key === "Enter") this.blur();
    };

    const cancelBtn = document.createElement("p");
    cancelBtn.className = "addon-cancel-button";
    cancelBtn.innerText = cancelTitle;

    cancelBtn.onclick = function () {
        cancelClicked(parseInt(container.id));
        container.remove();
    };

    container.appendChild(cancelBtn);
    container.appendChild(input);

    setTimeout(() => {
        if (!value) input.focus();
    }, 10);

    return container;
};

export default String;
