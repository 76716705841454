import Info from "../../Nodes/Info";
import { CheckSaleInNagative } from "../Utils/ItemsSyncMethods";

var ev2 = new Event("input", { bubbles: true });
var nativeInputValueSetter = Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, "value").set;

const CreateCounterNode = ({ item, onChange }) => {
    const node = document.createElement("div");
    node.className = "row";
    node.onclick = (e) => {
        e.target.id !== "itemQuantity" && onChange(e, item);
    };

    let plus = document.createElement("p");
    plus.innerText = "+";
    plus.id = "plus";
    node.append(plus);

    let quantity = document.createElement("input");
    quantity.defaultValue = item.billQuantity;
    quantity.id = "itemQuantity";
    quantity.type = "number";

    quantity.onfocus = ({ target }) => {
        target.value = "";
    };

    // quantity.onchange = (e) => {
    //     if (!e.target.value) {
    //         e.target.value = item.billQuantity;
    //         return;
    //     }
    //     let newQuantity = Math.round(parseFloat(e.target.value) * 100) / 100;
    //     if (item.quantity !== newQuantity) {
    //         item.newQuantity = newQuantity;
    //         onChange(e, item);
    //     }
    // };
    quantity.onkeydown = (e) => {
        // console.log(quantity.value, quantity.value.toString().length, quantity.value.includes("."));
        if (e.key === "Enter") quantity.blur();
        else if (e.key === "-") {
            Info({ title: "Error", content: "CantEnterNegative", type: "error" });
            e.preventDefault();
        }
        // else if (e.key === "0" && quantity.value.toString().length === 1 && !quantity.value.includes(".")) {
        //     e.preventDefault();
        // }
    };

    quantity.onblur = (e) => {
        if (!e.target.value || e.target.value === "0" || e.target.value === ".0") {
            e.target.value = item.billQuantity;
            return;
        } else if (CheckSaleInNagative(e.target.value, item)) {
            e.target.value = item.billQuantity;
            return;
        }
        let newQuantity = Math.round(parseFloat(e.target.value) * 100) / 100;
        if (item.billQuantity !== newQuantity) {
            item.newQuantity = newQuantity;
            onChange(e, item);
        }
    };
    // nativeInputValueSetter.call(quantity, value);
    // quantity.dispatchEvent(ev2);

    node.append(quantity);

    let minus = document.createElement("p");
    minus.innerText = "-";
    minus.id = "minus";
    node.append(minus);

    return node;
};

export default CreateCounterNode;
