import React from "react";
import Language from "../Utils/Language";

const Confirmer = ({ title, onAgreed, onDisagreed }) => (
    <div className="item-builder" style={{ padding: 20 }}>
        <p className="">
            <span style={{ fontSize: 16 }}>{Language.AreYouSure} </span>
            &#160; <br /> <br />
            <span style={{ fontWeight: 400 }}>{Language[title]}</span>
            <br /> <br /> <br />
            <span onClick={onAgreed} className="this-button">
                {Language.Yes}
            </span>
            &#160; &#160; &#160;
            <span onClick={onDisagreed} className="this-button">
                {Language.Cancel}
            </span>
        </p>
    </div>
);

export default Confirmer;
