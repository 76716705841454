const fomratter = new Intl.NumberFormat("en-US");
const CurrencyFromater = (amount, currency) => {
    let amoutn = fomratter.format(Round(amount));
    return amoutn + currency === 1 ? "د.ع" : currency === 2 ? "$" : "";
};
export default CurrencyFromater;

const Round = (amount) => Math.round(amount * 100) / 100;

export const DinarRound = (amount) => {
    amount = parseInt(amount);
    let stringPrice = amount.toString();
    if (stringPrice.length > 2) {
        let decimal = stringPrice.slice(stringPrice.length - 3);
        decimal = parseInt(decimal);
        if (decimal > 875) decimal = 1000;
        else if (decimal > 625) decimal = 750;
        else if (decimal > 375) decimal = 500;
        else if (decimal > 125) decimal = 250;
        else decimal = 0;
        amount = Math.floor(amount / 1000) * 1000 + decimal;
    }
    return amount;
};
