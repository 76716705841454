import "./searchOptions.css";
import React from "react";
import DefaultNoData from "../Defaults/DefaultNoData";
import DefaultItemBuilder from "../Defaults/DefaultItemBuilder";
import DefaultErrorBuilder from "../Defaults/DefaultErrorBuilder";
import DefaultLoader from "../Defaults/DefaultLoader";
import SearchOptionsGridNodeContainer from "./SearchOptionsGridNodeContainer";
import SearchableOptions from "./SearchableOptions";
import OwlSearchOptionsMethods from "./OwlSearchOptionsMethods";
import PageStateProvider from "./PageStateKit/PageStateProvider";
const Owl = {};

const prop = {
    key: "query",
    type: "String",
    title: "اسم الزبون",
    value: "زبون نقدي",
    showInClearBar: false,
    queryChanged,
};

function queryChanged(child) {
    Owl.queryParams[child.key] = child;
    Owl.search();
}

const OwlSearchOptionsContainer = ({
    url,
    api,
    queries = null,
    onResult = null,
    onError = null,
    itemBuilder = DefaultItemBuilder,
    useCash,
    firstLaunch,
    searchOptionsChange = () => {},
    className = "owl-search-scroller scroller",
    defaultValue,
}) => {
    console.debug("Owl Simple Container RDS");
    const containerRef = React.useRef(null);

    React.useEffect(() => {
        console.debug("Effect Owl Simple Container RD");
        if (Owl.init) return;
        OwlSearchOptionsMethods.getOwl(Owl, { url, api, queries, onResult, onError, useCash, firstLaunch });
        global.accountChanged = _searchOptionsChange;
        global.setPopup = global.setPopup;
        setTimeout(() => {
            const accountName = localStorage.getItem("bill-account");
            accountName && (containerRef.current.firstChild.firstChild.value = accountName);
        }, 100);
    }, []);

    function _searchOptionsChange(value) {
        if (!value) value = defaultValue;
        close();
        localStorage.setItem("bill-account", value.name);
        containerRef.current.firstChild.firstChild.value = value.name;
        searchOptionsChange(value);
    }

    function close() {
        Owl.setToggle(false);
        containerRef.current.firstChild.lastChild.classList.remove("toggled");
    }

    const toggleList = () => {
        if (Owl.toggled) return;
        Owl.setToggle(true);
        containerRef.current.firstChild.lastChild.classList.add("toggled");
        handleClose(close);
    };

    return (
        <div ref={containerRef} onClick={toggleList} id="owl-search-container" className="owl-search-container">
            <SearchableOptions prop={prop} Owl={Owl} />
            <SearchOptionsGridNodeContainer
                Owl={Owl}
                ItemBuilder={itemBuilder}
                searchOptionsChange={_searchOptionsChange}
                defaultValue={defaultValue}
                className={className}
            />
            {/* <PageStateProvider Owl={Owl} /> */}
        </div>
    );
};
export default OwlSearchOptionsContainer;

export function handleClose(close) {
    function handleMouseDown(e) {
        if (e.target.id === "search-new-account") {
            setTimeout(() => {
                close();
            }, 100);
            return false;
        } else if (e.target.id === "owl-search-input" || e.target.parentElement.id === "list-parent") {
            e.preventDefault();
            return false;
        }
        window.removeEventListener("mousedown", handleMouseDown);
        window.removeEventListener("touchstart", handleMouseDown);
    }
    window.addEventListener("mousedown", handleMouseDown);
    window.addEventListener("touchstart", handleMouseDown);
}
