import React from "react";
import OwlPrintContainer from "../../Print/OwlPrintContainer";
import Formatter from "../../Utils/Formatter";
import { API } from "../SalePointAPI";
import Language from "../Utils/Language";

const tableCols = [
    { key: "name", title: "الاسم", initialWidthFactor: 0.4 },
    { key: "billQuantity", title: "العدد", initialWidthFactor: 0.2 },
    { key: "price", title: "السعر", initialWidthFactor: 0.2 },
    { key: "total", title: "المجموع", initialWidthFactor: 0.2 },
];

const spliter = {
    cols: tableCols,
    storageKey: "print-table-cols",
    isTable: true,
    widthToDisplay: 0,
    showIndex: true,
    className: "owl-print-table",
    containerClass: "owl-print-container",
    // minColWidth: 0.1,
};
let init = false;
// let itemsCols = [];

const PrintSettingsRoute = () => {
    const banner = JSON.parse(sessionStorage.getItem("banner"));
    const logo = JSON.parse(localStorage.getItem("logo"));
    // { id: 15, displayIndex: 1, headerText: "المادة", name: null, propertyName: "c2", width: 6.09 },
    // c2: { prop: "Name", title: "المادة" },
    const [itemsCols, setItemsCols] = React.useState([]);
    const [selectedPaper, setSelectedPaper] = React.useState(localStorage.getItem("print-paper") || "210mm");
    const [billItems, setBillItems] = React.useState(JSON.parse(localStorage.getItem("print-table-items")) || []);
    const [fontSize, setFontSize] = React.useState(parseInt(localStorage.getItem("print-font-size")) || 100);

    const setStyle = () => {
        const container = document.getElementById("owl-print-container");
        if (container) container.style = `width :${selectedPaper};min-width:${selectedPaper};max-width:${selectedPaper};`;
    };

    React.useEffect(() => {
        localStorage.setItem("print-paper", selectedPaper);
        setStyle();
    }, [selectedPaper, billItems]);

    const setupCols = function (storedSettings) {
        let _itemsCols = [];
        let total = storedSettings.columns.reduce((a, b) => a + b.width, 0);

        // if (printSettings) printSettings = printSettings;
        function test() {
            spliter.setCols(_itemsCols.filter((o) => o.visible));
        }
        Object.entries(cols).forEach(([key, value]) => {
            let _col = storedSettings.columns.find(({ propertyName }) => propertyName === key);
            if (_col) {
                _itemsCols.push({
                    key: value.prop,
                    title: value.title,
                    initialWidthFactor: _col.width / total,
                    visible: true,
                    displayIndex: _col.displayIndex,
                });
            } else {
                _itemsCols.push({
                    key,
                    title: value.title,
                    //initialWidthFactor: 0.1,
                    displayIndex: 99,
                    visible: false,
                });
            }
        });
        _itemsCols = _itemsCols.sort((a, b) => a.displayIndex - b.displayIndex);
        spliter.tableCols = _itemsCols;
        setItemsCols(_itemsCols);
    };

    React.useEffect(() => {
        //58 mm
        //80 mm
        if (init) return;
        init = true;
        // setupCols(printSettings);
        //JSON.parse(localStorage.getItem("print-table-cols-props")) || printSettings);
        let stores = {};
        (JSON.parse(localStorage.getItem("inventory-list")) || { options: [{ id: -1, title: "all" }] }).options.slice(1).forEach((o) => {
            stores[o.id] = o.title;
        });

        let _items = [];
        if (billItems.length < 1)
            API.get("items?touch=true&limit=10&offset=0")
                .then(({ data }) => {
                    if (data.length > 5) data = data.slice(0, 3);
                    data.forEach((item) => {
                        let billQuantity = Math.round(Math.random() * Math.random() * 10) || 1;
                        _items.push({
                            name: item.name,
                            billQuantity,
                            price: Formatter(item.price, item.currencyId),
                            total: Formatter(item.price * billQuantity, item.currencyId),
                            storeName: stores[item.storeId] || "_",
                        });
                    });
                    localStorage.setItem("print-table-items", JSON.stringify(_items));
                    setBillItems(_items);
                })
                .catch((err) => {});
    }, []);

    const changeSize = (width) => {
        setSelectedPaper(width);
    };

    const changeFontSize = (size = 1) => {
        setFontSize((oldSize) => {
            let newSize = oldSize + size;
            localStorage.setItem("print-font-size", newSize);
            return newSize;
        });
    };

    return billItems.length > 0 ? ( //&& itemsCols.length > 0 ? (
        <OwlPrintContainer
            items={billItems}
            spliter={spliter}
            fontSize={fontSize / 10}
            footer={
                <div className="col-center p-2 relative" style={{ zIndex: 9999 }}>
                    <p className="text-center font-bold p-2">شركة المربع للحلول البرمجية </p>
                    <div className="row-center gap-3 m-3">
                        <p onClick={() => changeFontSize(1)} style={{ fontSize: 11 }} className={`this-button active`}>
                            {"+"}
                        </p>
                        <p style={{ fontSize: 11 }} className={`this-button`}>
                            {fontSize / 100}
                        </p>
                        <p onClick={() => changeFontSize(-1)} style={{ fontSize: 11 }} className={`this-button active`}>
                            {"-"}
                        </p>
                    </div>
                    <div className="row-center gap-2">
                        <p onClick={() => changeSize("210mm")} style={{ fontSize: 11 }} className={`this-button  ${selectedPaper === "210mm" ? "active" : ""}`}>
                            {"A4 "}
                        </p>
                        <p onClick={() => changeSize("80mm")} style={{ fontSize: 11 }} className={`this-button ${selectedPaper === "80mm" ? "active" : ""}`}>
                            {"mm 80 "}
                        </p>
                        <p onClick={() => changeSize("58mm")} style={{ fontSize: 11 }} className={`this-button  ${selectedPaper === "58mm" ? "active" : ""}`}>
                            {"mm 58 "}
                        </p>
                    </div>

                    <p id="close-print-setting" className={`this-button m-2`}>
                        {Language.Save}
                    </p>
                </div>
            }>
            <div className="col">
                {/*<div className="font-bold wrap gap-2 p-2">itemsCols.map((col) => {return (<p onClick={() => onColClicked(col)} className={`this-button ${col.visible ? "owl-active" : "owl-un-active"}`} key={col.key}>{col.title}</p>);</div> */}
                <img className="print-logo" src={`data:image/png;base64,${selectedPaper === "210mm" ? banner : logo}`} />
                <div className="row-center">{/* <p className="text-sm py-2"> {printerSettings?.note}</p> */}</div>
            </div>
        </OwlPrintContainer>
    ) : (
        <></>
    );
};

export default PrintSettingsRoute;

const cols = {
    // c1: { prop: "index", title: "ت" },
    c2: { prop: "name", title: "المادة" },
    c3: { prop: "billQuantity", title: "العدد" },
    // c4: { prop: "Item", title: "التعبئة" },
    c5: { prop: "price", title: "السعر" },
    c6: { prop: "total", title: "المجموع" },
    // c7: { prop: "CompanyName", title: "الشركة" },
    // c8: { prop: "ExpireDate", title: "Expire" },
    // c9: { prop: "Item", title: "B.N" },
    // c10: { prop: "ItemMeasure", title: "القياس" },
    // c11: { prop: "Item", title: "الاخراج المخزني" },
    // c12: { prop: "SmallestPrice", title: "سعر المفردة" },
    // c13: { prop: "Note", title: "ملاحظة1" },
    // c14: { prop: "Note2", title: "ملاحظة2" },
    c15: { prop: "storeName", title: "المخزن" },
    // c16: { prop: "Number", title: "العدد والتعبئة" },
    // c17: { prop: "Item", title: "الكمية" },
    // c18: { prop: "strDiscount", title: "الخصم" },
    // c19: { prop: "Item", title: "رمز المادة" },
    // c20: { prop: "Item", title: "الموقع" },
    // c21: { prop: "ItemImage", title: "الصورة" },
    // c22: { prop: "DiscountNumber", title: "خصم عدد" },
    // c23: { prop: "strBiggestFillPrice", title: "سعر الكبيرة" },
    // c24: { prop: "Barcode", title: "الباركود" },
};
// const cols = {
//     c1: { prop: "Index", title: "ت" },
//     c2: { prop: "Name", title: "المادة" },
//     c3: { prop: "Number", title: "العدد" },
//     c4: { prop: "Item", title: "التعبئة" },
//     c5: { prop: "StrSinglePrice", title: "السعر" },
//     c6: { prop: "TotalPrice", title: "المجموع" },
//     c7: { prop: "CompanyName", title: "الشركة" },
//     c8: { prop: "ExpireDate", title: "Expire" },
//     c9: { prop: "Item", title: "B.N" },
//     c10: { prop: "ItemMeasure", title: "القياس" },
//     c11: { prop: "Item", title: "الاخراج المخزني" },
//     c12: { prop: "SmallestPrice", title: "سعر المفردة" },
//     c13: { prop: "Note", title: "ملاحظة1" },
//     c14: { prop: "Note2", title: "ملاحظة2" },
//     c15: { prop: "StoreName", title: "المخزن" },
//     c16: { prop: "Number", title: "العدد والتعبئة" },
//     c17: { prop: "Item", title: "الكمية" },
//     c18: { prop: "strDiscount", title: "الخصم" },
//     c19: { prop: "Item", title: "رمز المادة" },
//     c20: { prop: "Item", title: "الموقع" },
//     c21: { prop: "ItemImage", title: "الصورة" },
//     c22: { prop: "DiscountNumber", title: "خصم عدد" },
//     c23: { prop: "strBiggestFillPrice", title: "سعر الكبيرة" },
//     c24: { prop: "Barcode", title: "الباركود" },
// };

// || {
//     logo: null,
//     note: "ضمان البطارية  الصغيرة لمدة ثلاثة اشهر.\r\nضمان البطارية الكبيرة لمدة ستة اشهر.\r\nضمان العاكسة لمدة سنة (صيانة).\r\nضمان ال UPS لمدة ستة اشهر.\r\n يجب شحن البطارية الجديدة لمدة لاتقل عن ثمانية ساعات\r\n\r\nضمان بطارية مكسيما ثلاثة اشهر.\r\nضمان منظم الفولتية مكسيما ثلاثة اشهر.\r\n\r\nلايوجد ضمان للبطارية في حال استخدام عاكسة عراقية الصنع.",
//     columns: [
//         // { id: 1, displayIndex: 0, headerText: "ت", name: null, propertyName: "c1", width: 1.27 },
//         // { id: 4, displayIndex: 12, headerText: "ملاحظات", name: null, propertyName: "c13", width: 2.78 },
//         // { id: 7, displayIndex: 9, headerText: "القياس", name: null, propertyName: "c10", width: 1.96 },
//         { id: 11, displayIndex: 5, headerText: "المجموع", name: null, propertyName: "c6", width: 3.28 },
//         { id: 12, displayIndex: 4, headerText: "السعر", name: null, propertyName: "c5", width: 2.33 },
//         { id: 14, displayIndex: 2, headerText: "العدد", name: null, propertyName: "c3", width: 1.53 },
//         { id: 15, displayIndex: 1, headerText: "المادة", name: null, propertyName: "c2", width: 6.09 },
//     ],
//     companyName: "مكتب الترائب لحلول الطاقة",
//     address: "شارع الصناعة",
//     phone: "009647735931737",
//     city: "baghdad",
//     banner: BannerTest,
// };
