import { Div, Span } from "../../Nodes/NodeBuilder";
import Utils from "../../Sale/Utils/Utils";

const StringOptions = ({ id, inputProps, onChange, cancel, saveTitle, options }) => {
    const container = document.createElement("div");
    container.className = "addon-input-container";
    id && (container.id = id);

    const input = document.createElement("input");
    Object.entries(inputProps).forEach(([key, value]) => {
        input[key] = value;
    });

    const _onChanged = function () {
        if (input.value) onChange({ value: input.value, option: select.value });
    };

    input.onkeydown = function (e) {
        let key = e.key;

        if (key === "Enter") _onChanged();
        else if (key === "0") {
            // if (input.value.length === 1 && input.value.startsWith("0")) e.preventDefault();
        } else if (key === "-" || key === "+") e.preventDefault();
    };
    const select = document.createElement("select");
    select.className = "addon-select";
    select.id = "addon-select";
    container.appendChild(select);

    const btn = document.createElement("p");
    if (cancel) {
        btn.innerText = cancel.title;
        btn.className = "addon-cancel-button";
        btn.onclick = () => cancel.onClick();
    } else if (saveTitle) {
        btn.className = "addon-save-button";
        btn.innerText = saveTitle;
        btn.onclick = _onChanged;
    }

    options.forEach((option) => {
        const optionElement = document.createElement("option");
        optionElement.value = option.key;
        optionElement.innerText = option.title;
        select.appendChild(optionElement);
    });

    if (inputProps.type === "number") {
        let symbol = localStorage.getItem("TheCurrency") || 1;
        const formated = Span({ className: "primary-text right-0 absolute", style: "letter-spacing:2px", innerText: "" });
        input.style.position = "relative";
        input.style.zIndex = 9;
        input.style = "opacity: 0";
        // formated.style.marginRight = "-100px";
        // formated.style = `left: ${90}px; top: ${input.offsetTop}; postion:absolute;`;
        const forNowConatiner = Div({ className: "relative row-center flex-grow" }, [formated, input]);

        input.oninput = (e) => {
            formated.innerText = Utils.Formatter(input.value, symbol);
        };
        select.onchange = () => {
            setTimeout(() => {
                symbol = select.value === "Rate" ? "Rate" : localStorage.getItem("TheCurrency") || 1;
                formated.innerText = Utils.Formatter(input.value, symbol);
            }, 10);
            input.focus();
        };
        container.appendChild(forNowConatiner);
    } else {
        select.onchange = () => {
            input.focus();
        };
        container.appendChild(input);
    }

    btn.innerText && container.appendChild(btn);

    setTimeout(() => {
        input.focus();
    }, 300);

    container.addEventListener("focusout", (e) => {
        setTimeout(() => {
            if (container.contains(e.relatedTarget) || onChange(input.value ? { value: input.value, option: select.value } : null) || input.value === null)
                return;
            input.focus();
        }, 100);
    });

    return container;
};

export default StringOptions;
