import React from "react";
import Boolean from "../../QueryKit.js/Boolean";
import Settings from "../Routs/Settings";
import { getStoredSettings, Version } from "../Utils/Consts";
import Language from "../Utils/Language";

const Footer = ({ Owl }) => {
    const user = JSON.parse(localStorage.getItem("logged-user")) || { name: Language.TheUser };
    [Owl.fetchAll, Owl.setFetchAll] = React.useState(localStorage.getItem("fetch-all") === "true");
    const navigate = function (url) {
        window.open(url, "_blank", "noopener,noreferrer");
        // window.open("http://twitter.com/saigowthamr");
        // window.open(window.location.origin + url, "_blank");
    };
    return (
        <div id="footer" className="row-center gap-3">
            {/* <p onClick={() => navigate("/settings")} href="/settings" target="_blank" className="link-btn">
                {Language.Settings}
            </p> */}
            <p
                onClick={() => {
                    global.setPopup({
                        page: <Settings Owl={Owl} storedSettings={getStoredSettings()} />,
                        close: () => {
                            Owl.closePopup && Owl.closePopup();
                        },
                    });
                }}
                className="this-button bg-white">
                {Language.Settings}
            </p>
            <p className="this-button bg-white" onClick={() => global.setPopup({ page: <Logout close={() => global.setPopup(null)} /> })}>
                {user.name}
            </p>
            <Boolean
                prop={{
                    value: Owl.fetchAll,
                    storageKey: "fetch-all",
                    queryChanged: () => {
                        if (!Owl.fetchAll)
                            setTimeout(() => {
                                Owl.fetch();
                            }, 100);
                        Owl.setFetchAll(!Owl.fetchAll);
                    },
                    key: "ferch-all",
                }}
            />
            {/* <input type="checkbox" checked={Owl.fetchAll} onChange={() => Owl.setFetchAll(!Owl.fetchAll)} /> */}
            <p className="font-bold owl-text-sm" onClick={() => global.setPopup({ page: <Logout close={() => global.setPopup(null)} /> })}>
                {Language.FetchAll}
            </p>
            <p style={{ marginRight: "auto", marginLeft: 20 }} className="primary-text text-gray-500">
                {Version}
            </p>
        </div>
    );
};

function logout() {
    let token = localStorage.getItem("token");
    localStorage.clear();
    localStorage.setItem("token", token);
    window.location.reload();
    // window.open("/", "", "noopener,noreferrer");
}

const Logout = ({ close }) => {
    return (
        <div className="item-builder" style={{ padding: 20 }}>
            <p className="">
                <span style={{ fontSize: 16 }}>{Language.Logout} </span>
                &#160;
                <br />
                <br />
                <span style={{ fontWeight: 500 }}>{Language.LogoutMessageWorrning}</span>
                <br />
                <span style={{ fontWeight: 500 }}>{Language.LogoutMessage}</span>
                <br />
                <br />
                <br />
                <span onClick={logout} className="this-button">
                    {Language.Yes}
                </span>
                &#160; &#160; &#160;
                <span onClick={close} className="this-button">
                    {Language.Cancel}
                </span>
            </p>
            <p></p>
        </div>
    );
};

export default Footer;
