import React from "react";
import OwlPrintContainer from "../../Print/OwlPrintContainer";
import Language from "../Utils/Language";

const tableCols = [
    { key: "name", title: "الاسم", initialWidthFactor: 0.5 },
    { key: "billQuantity", title: "العدد", initialWidthFactor: 0.1 },
    { key: "price", title: "السعر", initialWidthFactor: 0.2 },
    { key: "total", title: "المجموع", initialWidthFactor: 0.3 },
];

const spliter = {
    isTable: true,
    showIndex: true,
    cols: tableCols,
    widthToDisplay: 0,
    className: "owl-print-table",
    storageKey: "print-table-cols",
    // minColWidth: 0.1,
};
const banner = JSON.parse(sessionStorage.getItem("banner"));
const logo = JSON.parse(localStorage.getItem("logo"));

let printing = false;
const PrintRoute = () => {
    const { billDetails, billItems } = React.useMemo(() => JSON.parse(localStorage.getItem("print-data")), []);

    React.useEffect(() => {
        if (printing) return;
        printing = true;
        const afterprint = (event) => {
            console.log("After print");
            window.close();
        };
        setTimeout(() => {
            window.print();
            // window.addEventListener("afterprint", afterprint);
            window.onafterprint = afterprint;
        }, 300);
        // return window.addEventListener("afterprint", afterprint);
    }, []);
    console.log({ view: billDetails.billFinancial1 });

    return React.useMemo(
        () => (
            <OwlPrintContainer
                items={billItems}
                spliter={spliter}
                fontSize={parseInt(localStorage.getItem("print-font-size")) / 10 || 10}
                footer={
                    <div className="col-center m-4">
                        {billDetails.note && <p>{`${Language.Note}  : ${billDetails.note}`}</p>}
                        <p>{`${Language.TheTotal}  : ${billDetails.billFinancial1?.total}`}</p>
                        {billDetails.billFinancial1?.discount > 0 && (
                            <>
                                <p>{`${Language.TheDiscount}  : ${billDetails.billFinancial1?.discount}`}</p>
                                <p>{`${Language.TheTotalAfterDiscount}  : ${billDetails.billFinancial1.net}`}</p>
                            </>
                        )}
                        <p>{`${Language.Paid} : ${billDetails.billFinancial1?.paid}`}</p>
                        {billDetails.account && billDetails.account.id !== 999 && (
                            <>
                                <p>{`${Language.Remaining}  : ${billDetails.billFinancial1.net - billDetails.billFinancial1.paid}`}</p>
                            </>
                        )}
                        <p className="text-center font-bold p-2" style={{ marginTop: 10, fontSize: 8 }}>
                            {Language.AlMorabaa}
                        </p>
                        {/* <JsonToView item={billDetails} /> */}
                    </div>
                }>
                <div className="col-center">
                    {/*<div className="font-bold wrap gap-2 p-2">itemsCols.map((col) => {return (<p onClick={() => onColClicked(col)} className={`this-button ${col.visible ? "owl-active" : "owl-un-active"}`} key={col.key}>{col.title}</p>);</div> */}
                    <img className="print-logo" src={`data:image/png;base64,${localStorage.getItem("print-paper") !== "210mm" ? logo : banner}`} />
                    <p>{Language[billDetails.header]}</p>
                    <div className="print-bill-header">
                        <p>{`${Language.TheDate}  : ${new Date().toLocaleString()}`}</p>
                        <p>{`${Language.BillNumber}  : ${billDetails.billNumber}`}</p>
                        <p>{`${Language.TheName}  : ${billDetails.account?.name}`}</p>
                        <p>{`${Language.TheUser} : ${billDetails.user?.name}`}</p>
                    </div>
                    <div className="row-center">{/* <p className="text-sm py-2"> {printerSettings?.note}</p> */}</div>
                </div>
            </OwlPrintContainer>
        ),
        []
    );
};

export default PrintRoute;

const cols = {
    c1: { prop: "index", title: "ت" },
    c2: { prop: "name", title: "المادة" },
    c3: { prop: "billQuantity", title: "العدد" },
    c4: { prop: "Item", title: "التعبئة" },
    c5: { prop: "price", title: "السعر" },
    c6: { prop: "total", title: "المجموع" },
    c7: { prop: "CompanyName", title: "الشركة" },
    c8: { prop: "ExpireDate", title: "Expire" },
    c9: { prop: "Item", title: "B.N" },
    c10: { prop: "ItemMeasure", title: "القياس" },
    c11: { prop: "Item", title: "الاخراج المخزني" },
    c12: { prop: "SmallestPrice", title: "سعر المفردة" },
    c13: { prop: "Note", title: "ملاحظة1" },
    c14: { prop: "Note2", title: "ملاحظة2" },
    c15: { prop: "storeName", title: "المخزن" },
    c16: { prop: "Number", title: "العدد والتعبئة" },
    c17: { prop: "Item", title: "الكمية" },
    c18: { prop: "strDiscount", title: "الخصم" },
    c19: { prop: "Item", title: "رمز المادة" },
    c20: { prop: "Item", title: "الموقع" },
    c21: { prop: "ItemImage", title: "الصورة" },
    c22: { prop: "DiscountNumber", title: "خصم عدد" },
    c23: { prop: "strBiggestFillPrice", title: "سعر الكبيرة" },
    c24: { prop: "Barcode", title: "الباركود" },
};

//  {
//     logo: null,
//     note: "ضمان البطارية  الصغيرة لمدة ثلاثة اشهر.\r\nضمان البطارية الكبيرة لمدة ستة اشهر.\r\nضمان العاكسة لمدة سنة (صيانة).\r\nضمان ال UPS لمدة ستة اشهر.\r\n يجب شحن البطارية الجديدة لمدة لاتقل عن ثمانية ساعات\r\n\r\nضمان بطارية مكسيما ثلاثة اشهر.\r\nضمان منظم الفولتية مكسيما ثلاثة اشهر.\r\n\r\nلايوجد ضمان للبطارية في حال استخدام عاكسة عراقية الصنع.",
//     columns: [
//         // { id: 1, displayIndex: 0, headerText: "ت", name: null, propertyName: "c1", width: 1.27 },
//         // { id: 4, displayIndex: 12, headerText: "ملاحظات", name: null, propertyName: "c13", width: 2.78 },
//         // { id: 7, displayIndex: 9, headerText: "القياس", name: null, propertyName: "c10", width: 1.96 },
//         { id: 11, displayIndex: 5, headerText: "المجموع", name: null, propertyName: "c6", width: 3.28 },
//         { id: 12, displayIndex: 4, headerText: "السعر", name: null, propertyName: "c5", width: 2.33 },
//         { id: 14, displayIndex: 2, headerText: "العدد", name: null, propertyName: "c3", width: 1.53 },
//         { id: 15, displayIndex: 1, headerText: "المادة", name: null, propertyName: "c2", width: 6.09 },
//     ],
//     companyName: "مكتب الترائب لحلول الطاقة",
//     address: "شارع الصناعة",
//     phone: "009647735931737",
//     city: "baghdad",
//     banner: BannerTest,
// };
