import React from "react";
import BillDetailsContainer from "./Components/BillDetailsContainer";
import OwlContainer from "../Containers/OwlContainer";
import FirstLaunch from "../Utils/FirstLaunch";
import Consts from "./Utils/Consts";
import { API } from "./SalePointAPI";
import ItemNode from "./Items/ItemNode";
import Footer from "./Components/Footer";

const SalePointView = () => {
    return (
        <OwlContainer
            api={API}
            url={`items`}
            firstLaunch={FirstLaunch}
            itemBuilder={ItemNode}
            detailsBuilder={BillDetailsContainer}
            queries={Consts.queries}
            useCash={false} // localStorage.getItem("UseCash") !== "Hide"}
            spliter={Consts.spliter}
            poupKeyboard={localStorage.getItem("PoupKeyborad") !== "UnActive"}
            footer={Footer}
            // onResult={onResult}
        />
    );
};
export default SalePointView;
// const etst = {
//     logo: null,
//     note: "ضمان البطارية  الصغيرة لمدة ثلاثة اشهر.\r\nضمان البطارية الكبيرة لمدة ستة اشهر.\r\nضمان العاكسة لمدة سنة (صيانة).\r\nضمان ال UPS لمدة ستة اشهر.\r\n يجب شحن البطارية الجديدة لمدة لاتقل عن ثمانية ساعات\r\n\r\nضمان بطارية مكسيما ثلاثة اشهر.\r\nضمان منظم الفولتية مكسيما ثلاثة اشهر.\r\n\r\nلايوجد ضمان للبطارية في حال استخدام عاكسة عراقية الصنع.",
//     columns: [
//         { id: 1, displayIndex: 0, headerText: "ت", name: null, propertyName: "c1", width: 1.27 },
//         { id: 4, displayIndex: 12, headerText: "ملاحظات", name: null, propertyName: "c13", width: 2.78 },
//         { id: 7, displayIndex: 9, headerText: "القياس", name: null, propertyName: "c10", width: 1.96 },
//         { id: 11, displayIndex: 5, headerText: "المجموع", name: null, propertyName: "c6", width: 3.28 },
//         { id: 12, displayIndex: 4, headerText: "السعر", name: null, propertyName: "c5", width: 2.33 },
//         { id: 14, displayIndex: 2, headerText: "العدد", name: null, propertyName: "c3", width: 1.53 },
//         { id: 15, displayIndex: 1, headerText: "المادة", name: null, propertyName: "c2", width: 6.09 },
//     ],
//     companyName: "مكتب الترائب لحلول الطاقة",
//     address: "شارع الصناعة",
//     phone: "009647735931737",
//     city: "baghdad",
// };
