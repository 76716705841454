import React from "react";
const getClass = (prop) => "owl-toggleed-bg" + (prop.value ? " owl-toggleed" : "");
const BooleanText = ({ prop }) => {
    console.debug("Boolean RD");

    return (
        <div style={{ marginRight: "auto" }} className={`owl-toggle-button text ${prop.className}`} key={prop.key}>
            <p
                onClick={(e) => {
                    prop.value = !prop.value;
                    let target = e.currentTarget;
                    target.className = getClass(prop);
                    if (prop.storageKey) localStorage.setItem(prop.storageKey, prop.value);
                    if (prop.showInClearBar)
                        prop.clear = () => {
                            prop.value = !prop.value;
                            target.className = getClass({ value: false });
                        };
                    prop.queryChanged();
                }}
                className={getClass(prop)}>
                {/* <span /> */}
                <span className="font-bold" style={{ fontSize: 12 }}>
                    {prop.title}
                </span>
            </p>
        </div>
    );
};

export default BooleanText;
