import React from "react";
import SearchingIcon from "../Defaults/SearchingIcon";

const Searching = () => {
    return <div className="owl-loading " />;
    return (
        <div className="searching-icon-container">
            <SearchingIcon />
        </div>
    );
};

export default Searching;
