import React from "react";
import { useNavigate } from "react-router-dom";

const RedirectRoute = () => {
    const navigater = useNavigate();

    React.useEffect(() => {
        navigater(localStorage.getItem("logged-user") ? "/" : "/login");
    }, []);
};

export default RedirectRoute;
