import React, { Fragment } from "react";

const Table = ({ Owl, spliter, items }) => {
    console.debug("%Table Print", "color: #0f0");
    Owl.ResizebleObj.tableRef = React.useRef(null);
    // [spliter.cols, spliter.setCols] = React.useState(spliter.cols);
    // React.useEffect(() => {
    //     return;

    //     Owl.ResizebleObj.tableCols = spliter.cols.map((col) => col.initialWidthFactor);
    //     if (spliter.showIndex) Owl.ResizebleObj.GetStyle = () => `30px ${Owl.ResizebleObj.tableCols.map((_col) => `${_col}fr`).join(" ")}`;
    //     else Owl.ResizebleObj.GetStyle = () => Owl.ResizebleObj.tableCols.map((_col) => `${_col}fr`).join(" ");
    //     Owl.ResizebleObj.style = Owl.ResizebleObj.GetStyle();
    //     Owl.ResizebleObj.tableRef.current.style.gridTemplateColumns = Owl.ResizebleObj.style;
    // }, [spliter.cols]);

    return (
        <div ref={Owl.ResizebleObj.tableRef} className={spliter.className} style={{ gridTemplateColumns: Owl.ResizebleObj.style }}>
            <Fragment>
                {/* <div className="print-table-header" style={{ gridTemplateColumns: Owl.ResizebleObj.style }}></div> */}
                <IndexTitleTd />
                {spliter.cols.map((col, i) => (
                    <TitleTd key={i} resizeTregered={Owl.ResizebleObj.resizeTregered} lastOne={spliter.cols.length - 1 === i} col={col.title} i={i} />
                ))}
                {items.map((item, i) => (
                    <Fragment key={i}>
                        <OddIindexTd title={i + 1} />
                        {spliter.cols.map(({ key }) => (
                            <OddTd key={key} title={item[key]} />
                        ))}
                    </Fragment>
                ))}
            </Fragment>
        </div>
    );
};

export default Table;

const OddIindexTd = ({ title }) => <span className="owl-print-table-td owl-print-content">{title}</span>;
const OddTd = ({ title }) => <span className="owl-print-table-td owl-print-content">{title}</span>;

const TitleTd = ({ col, lastOne, i, resizeTregered }) =>
    !lastOne ? (
        <div
            className="col relative owl-print-table-header-td"
            onTouchStart={({ touches }) => {
                resizeTregered(touches[0].clientX, i, true);
            }}
            onMouseDown={(e) => {
                if (e.target.id === "resizeble-target") resizeTregered(e.clientX, i);
            }}>
            {/* {i === 0 && <p className="print-table-line cursor-default" />} */}
            <p className="cursor-default p-1">{col}</p>
            <p id="resizeble-target" className="print-table-line" />
        </div>
    ) : (
        <div className="col relative owl-print-table-header-td">
            <p className="p-1">{col}</p>
            <p className="print-table-line left-0" />
        </div>
    );

const IndexTitleTd = ({ col = "ت" }) => (
    <div className="col relative owl-print-table-header-td">
        {/* <p className="print-table-line cursor-default" /> */}
        {/* <p className="table-line cursor-default left-0" /> */}
        <p className="cursor-default p-1">{col}</p>
    </div>
);
