import React from "react";
import Formatter, { CurrencyFromater } from "../../Utils/Formatter";
import { CurrenciesTitle } from "../Utils/Consts";
import Language from "../Utils/Language";
let billFinancial1 = { note: null };

const calclateDiscountByType = {
    Severed: (discount, total) => total - discount,
    Rate: (discount, total) => total - (total * discount) / 100,
};

const Finaciall = ({ Owl }) => {
    const [, render] = React.useState(0);

    React.useEffect(() => {
        if (Owl.updateBillFinancial) return;
        Owl.updateBillFinancial = function () {
            billFinancial1 = Owl.billItems.billFinancial1;
            let discount = calclateDiscountByType[billFinancial1.discountType](billFinancial1.discount, billFinancial1.total);

            billFinancial1.net = discount;
            if (billFinancial1.account && billFinancial1.account.id !== 999) {
                billFinancial1.remaining = billFinancial1.net - billFinancial1.paid;
            }
            Owl.storTheBills && Owl.storTheBills();
            render(Math.random());
        };
    }, []);

    // React.useEffect(() => {
    //     console.log(billFinancial1);
    // }, [billFinancial1]);

    function onChange(value) {
        billFinancial1.paid = value;
        Owl.updateBillFinancial();
    }
    function onDelteNoteChange() {
        billFinancial1.note = null;
        Owl.updateBillFinancial();
    }
    function onNoteChange(value) {
        Owl.storTheBills();
    }

    return (
        <div className="details-grid font-bold">
            <Label title={Language.TheTotal} amount={billFinancial1.total} />
            {billFinancial1.discount > 0 && (
                <>
                    {billFinancial1.discountType === "Severed" ? (
                        <Label title={Language.TheDiscount} amount={billFinancial1.discount} />
                    ) : (
                        <LabelRate title={Language.TheDiscount} amount={billFinancial1.discount} />
                    )}
                    <Label title={Language.TheTotalAfterDiscount} amount={billFinancial1.net} />
                </>
            )}
            {billFinancial1.account && billFinancial1.account.id !== 999 && (
                <>
                    <InputLabel title={Language.Paid} amount={billFinancial1.paid} onChange={onChange} />
                    <Label title={Language.Remaining} amount={billFinancial1.remaining} />
                </>
            )}
            {billFinancial1.note !== null && (
                // <p>
                //     <span className="text-red-400 rounded-md" style={{}}>
                //         {Language.Delete}
                //     </span>
                //     <span>{Language.Note} : </span>
                //     <span>{billFinancial1.note}</span>
                // </p>
                <div className="font-bold addon-input-container col-span-full">
                    <input
                        className="addon-input"
                        onChange={({ target }) => {
                            billFinancial1.note = target.value;
                        }}
                        defaultValue={billFinancial1.note}
                        placeholder={Language.AddNote}
                        maxLength={100}
                        autoFocus={billFinancial1.note === ""}
                        onBlur={onNoteChange}
                    />
                    <p onClick={onDelteNoteChange} className="addon-cancel-button">
                        {Language.CencelTheNote}
                    </p>
                </div>
            )}
        </div>
    );
};

const InputLabel = ({ title, amount, onChange }) => (
    <>
        <p style={{ minWidth: 120 }}>{title}</p>
        <div className="row-center">
            <input
                className="paid-input"
                type="number"
                onKeyDown={(e) => {
                    if (e.keyCode === 13) e.target.blur();
                }}
                onBlur={({ target }) => {
                    if (target.value >= 0 || target.value === "") target.value = amount;
                    onChange(target.value);
                }}
                onFocus={({ target }) => {
                    target.value = "";
                }}
                defaultValue={amount}
                onChange={({ target }) => {
                    amount = target.value;
                }}
            />
            <span style={{ marginRight: -24 }}>{CurrenciesTitle[billFinancial1.currencyId]}</span>
        </div>
    </>
);

const Label = ({ title, amount }) => (
    <>
        <p className="font-bold">{title}</p>
        <p className="font-bold px-1">{CurrencyFromater(amount, billFinancial1.currencyId)}</p>
    </>
);

const LabelRate = ({ title, amount }) => (
    <>
        <p className="font-bold">{title}</p>
        <p className="font-bold px-1">
            {amount}
            {"%"}
        </p>
    </>
);

function getAmountNode(amount, currencyId, title, parent) {
    const pNode = document.createElement("p");
    pNode.style.display = "flex";

    const nodeTitle = document.createElement("span");
    nodeTitle.innerText = title;
    nodeTitle.style.minWidth = "120px";

    const nodeValue = document.createElement("span");
    nodeValue.innerText = Formatter(amount, currencyId);

    pNode.appendChild(nodeTitle);
    pNode.appendChild(nodeValue);
    parent.appendChild(pNode);
}
export default Finaciall;
