const fomratter = new Intl.NumberFormat("en-US");
const Formatter = (amount) => fomratter.format(amount);
export default Formatter;

export const CurrencyFromater = (amount, currency = localStorage.getItem("TheCurrency")) => {
    let _amoutn = fomratter.format(Round(amount));
    return _amoutn + (currency == 1 ? " د.ع " : currency == 2 ? " $ " : "");
};

export const Round = (amount) => Math.round(amount * 1000) / 1000;
