import { API } from "../Sale/SalePointAPI";
import ItemsSyncMethods from "../Sale/Utils/ItemsSyncMethods";

const FirstLaunch = (Owl) => {
    Promise.all([getGropus(), getStorse(), getPrintSettings(), getDistributors()]).catch((err) => {});
    setTimeout(() => {
        ItemsSyncMethods.SetupStorageMethods(Owl);
        ItemsSyncMethods.AddItemDetailsEvent(Owl);
        let billsIds = JSON.parse(localStorage.getItem("billsIds"));
        billsIds.billId && Owl.switchBills(billsIds.billId, false);
    }, 10);
};

const getGropus = async () => {
    let groupChildren = [{ key: "groupId", title: "الكل", id: -1 }];
    const { data } = await API.get("groups?limit=100");
    data.forEach((element) => {
        groupChildren.push({ title: element.name, id: element.id });
    });
    const toLocalStorage = { key: "groupId", type: "ButtonsOptions", value: -1, options: groupChildren, storageKey: "selected-group" };
    localStorage.setItem("groups-list", JSON.stringify(toLocalStorage));
};

const getStorse = async () => {
    let storesChildren = [{ key: "stoteId", title: "كل المتاجر", id: -1 }];
    const { data } = await API.get("stores?limit=100&userId=" + JSON.parse(localStorage.getItem("logged-user")).id);
    data.forEach((element) => {
        storesChildren.push({ title: element.name, id: element.id });
    });
    const toLocalStorage = { key: "storeId", type: "Options", value: -1, options: storesChildren, storageKey: "selected-store" };
    if (!localStorage.getItem("inventory-list"))
        setTimeout(() => {
            window.location.reload();
        }, 300);
    localStorage.setItem("inventory-list", JSON.stringify(toLocalStorage));
};
const getPrintSettings = async () => {
    if (localStorage.getItem("banner")) return;
    const { data } = await API.get("printsettings");
    sessionStorage.setItem("banner", JSON.stringify(data.banner));
    localStorage.setItem("logo", JSON.stringify(data.logo));
};
const getDistributors = async () => {
    const { data } = await API.get(`accounts?UserId=${JSON.parse(localStorage.getItem("logged-user")).id}&limit=30&offset=0&distributorsOnly=true`);
    if (data.length < 1) return;
    // console.log({ data });
    let distributors = [{ title: "بدون موزع", id: 0 }];
    data.forEach((element) => {
        distributors.push({ title: element.name, id: element.id });
    });
    localStorage.setItem("distributors-accounts", JSON.stringify(distributors));
};

export default FirstLaunch;
