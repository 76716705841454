import React from "react";

const Progressing = ({ data }) => {
    return (
        <div className="fixed inset-0 col-center justify-center" style={{ zIndex: 1000, backgroundColor: "#63cfc955" }}>
            <div className="lds-ring">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    );
};

export default Progressing;
