class Utils {
    static convertNumbers2English = (Num) => Num.replace(/[٠-٩]/g, (d) => "٠١٢٣٤٥٦٧٨٩".indexOf(d)).replace(/[۰-۹]/g, (d) => "۰۱۲۳۴۵۶۷۸۹".indexOf(d));
    static Round = (num) => Math.round(num * 100) / 100;

    static getStorage = (key) => JSON.parse(localStorage.getItem(key));
    static setStorage = (key, value) => localStorage.setItem(key, JSON.stringify(value));

    static getSymbol = (id) => ({ 1: "د.ع", 2: "$", Rate: "%" }[id] || "");

    static Formatter = (amount, id) => new Intl.NumberFormat("en-US").format(this.convertNumbers2English(amount)) + " " + this.getSymbol(id);

    static removeAllChildNodes = (parent) => {
        parent.replaceChildren([]);
    };
}
export default Utils;
