import React from "react";
import OwlScroller from "./OwlScroller";
import DefaultNoData from "../Defaults/DefaultNoData";
import DefaultItemBuilder from "../Defaults/DefaultItemBuilder";
import DefaultErrorBuilder from "../Defaults/DefaultErrorBuilder";
import DefaultLoader from "../Defaults/DefaultLoader";
import OwlMethods from "../Utils/OwlMethods";
import TableOrGirdContainer from "./TableOrGirdContainer";
import GridNodeContainer from "./GridNodeContainer";
import OwlItems from "./OwlItems";
import SplitContainer from "./SplitContainer ";
import SplitDetailsContainer from "./SplitDetailsContainer";
import GridContainer from "./GridContainer";
import PopupBuilder from "../Popups/PopupBuilder";
import { SettingsOptions } from "../Sale/Utils/Consts";
import { DinarRound } from "../Utils/CurrencyFromater";

const splitCols = [0.6, 0.4];

const tableCols = [
    { key: "id", title: "الرقم", initialWidthFactor: 0.6 },
    { key: "name", title: "السعر", initialWidthFactor: 0.2 },
    { key: "quantity", title: "العدد", initialWidthFactor: 0.2 },
];

const _spliter = {
    cols: splitCols,
    containerClass: "split-container",
    storageKey: "split-colums",
    // isTable: true,
    // widthToDisplay: 1000,
    // className: "owl-table",
    // showIndex: true,
    // minColWidth: 0.1,
};

const Owl = {
    billItems: { billFinancial1: {} },
};

const OwlContainer = ({
    url,
    api,
    queries = null,
    // onResult = null,
    onError = null,
    loader = DefaultLoader,
    noData = DefaultNoData,
    errorBuilder = DefaultErrorBuilder,
    header,
    itemBuilder = DefaultItemBuilder,
    gridClass = "owl-grid",
    queryContainerClass = "owl-query-conainer",
    detailsClass = "details-container",
    detailsBuilder,
    useCash,
    firstLaunch,
    spliter,
    poupKeyboard,
    footer: Footer,
    popup,
}) => {
    console.debug("OwlContainer RDS");

    const onResult = async (data) => {
        if (Owl.fetchAll) {
            const scroller = document.getElementById("owl-scroller");
            setTimeout(() => {
                scroller.scrollTo({ top: scroller.scrollHeight, left: 0 });
            }, 1);
        }

        return SettingsOptions.TheCurrency.value != "2"
            ? data.map((item) => {
                  item.id = item.id + "-" + item.storeId;
                  if (item.packages) {
                      item.packages.forEach((pack) => {
                          pack.price = DinarRound(pack.price);
                          pack.billQuantity = 0;
                      });
                      console.log(item.packages);
                  } else {
                      item.billQuantity = 0;
                      item.price = DinarRound(item.price);
                  }
                  return item;
              })
            : data.map((item) => {
                  item.id = item.id + "-" + item.storeId;
                  item.billQuantity = 0;
                  return item;
              });
    };

    React.useMemo(() => {
        console.debug("Effect Owl Container RD");
        OwlMethods.getOwl(Owl, { url, api, queries, onResult, onError, useCash, spliter, firstLaunch, poupKeyboard });
    }, []);

    const queryChanged = (child) => {
        if (child.clear)
            Owl.setQueryParams((_queryParams) => {
                _queryParams[child.key] = child;
                return { ..._queryParams };
            });
        else Owl.queryParams[child.key] = child;
        Owl.search();
    };

    return (
        <SplitContainer className={spliter.className} Owl={Owl} ResizebleObj={Owl.ResizebleObj}>
            <div className="z-10">
                <OwlItems queries={queries} queryContainerClass={queryContainerClass} Owl={Owl} queryChanged={queryChanged} header={header} />
            </div>
            <OwlScroller Owl={Owl}>
                <GridNodeContainer Owl={Owl} ItemBuilder={itemBuilder} className={gridClass} />
            </OwlScroller>
            <Footer Owl={Owl} />
            <SplitDetailsContainer Owl={Owl} ResizebleObj={Owl.ResizebleObj} className={detailsClass} DetailsBuilder={detailsBuilder} />
        </SplitContainer>
    );

    // if (spliter) {
    //     return spliter.isTable ? (
    //         <OwlScroller Owl={Owl}>
    //             <OwlItems queries={queries} queryContainerClass={queryContainerClass} Owl={Owl} queryChanged={queryChanged} header={header} />
    //             <TableOrGirdContainer Owl={Owl} ItemBuilder={itemBuilder} className={gridClass} />
    //         </OwlScroller>
    //     ) : (
    //         <SplitContainer className={spliter.className} Owl={Owl} ResizebleObj={Owl.ResizebleObj}>
    //             <div className="z-10">
    //                 <OwlItems queries={queries} queryContainerClass={queryContainerClass} Owl={Owl} queryChanged={queryChanged} header={header} />
    //             </div>
    //             <OwlScroller Owl={Owl}>
    //                 <GridNodeContainer Owl={Owl} ItemBuilder={itemBuilder} className={gridClass} />
    //             </OwlScroller>
    //             <Footer Owl={Owl} />
    //             <SplitDetailsContainer Owl={Owl} ResizebleObj={Owl.ResizebleObj} className={detailsClass} DetailsBuilder={detailsBuilder} />
    //             <PopupBuilder Owl={Owl} />
    //         </SplitContainer>
    //     );
    // } else
    //     return (
    //         <OwlScroller Owl={Owl}>
    //             <OwlItems queries={queries} queryContainerClass={queryContainerClass} Owl={Owl} queryChanged={queryChanged} header={header} />
    //             <GridContainer Owl={Owl} ItemBuilder={itemBuilder} className={gridClass} />
    //         </OwlScroller>
    //     );
};

export default OwlContainer;
