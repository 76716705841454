import React from "react";
import Language from "../Utils/Language";
import Confirmer from "./Confirmer";

const RadioButton = ({ options }) => {
    const containerRef = React.useRef(null);
    function onChange(value) {
        options.value = value;
        if (options.worrning) {
            localStorage.setItem(options.name, value);
        } else
            Object.values(containerRef.current.lastChild.children).forEach((child) => {
                if (child.id === options.value) child.classList.add("active");
                else child.classList.remove("active");
                localStorage.setItem(options.name, value);
            });
    }

    return (
        <div ref={containerRef} className=" row-center font-bold gap-4 p-3 owl-text-xs">
            <p className="radio-label owl-text-sm">{Language[options.name]}</p>
            <p className={`owl-radio-container pointer`}>
                {options.values.map((value) => (
                    <span
                        key={value}
                        id={value}
                        onClick={() => {
                            if (options.worrning) {
                                let oldPopup = global.popup;
                                global.setPopup({
                                    page: (
                                        <Confirmer
                                            onAgreed={() => {
                                                onChange(value);
                                                global.setPopup(oldPopup);
                                            }}
                                            title={options.worrning}
                                            onDisagreed={() => global.setPopup(oldPopup)}
                                        />
                                    ),
                                });
                            } else onChange(value);
                        }}
                        className={value === options.value ? "active" : ""}>
                        {Language[value]}
                    </span>
                ))}
            </p>
        </div>
    );
};
export default RadioButton;
