import React from "react";
import { API } from "../SalePointAPI";
import Formatter from "../../Utils/Formatter";
import SeparatedAddons from "../../Addons/SeparatedAddons";
import Language from "../Utils/Language";
import IconButton from "./IconButton";
import Finaciall from "./Finaciall";
import Button from "./Button";
import Info from "../../Nodes/Info";
import { billType, SettingsOptions } from "../Utils/Consts";
import Boolean from "../../QueryKit.js/Boolean";
import BooleanText from "../../QueryKit.js/BooleanText";
import AddAccount from "./AddAccount";

const notes = [];
let discountValue = null;

const calclateDiscountByType = {
    Severed: (discount, total) => {
        return total - discount;
    },
    Rate: (discount, total) => {
        return total - (total * discount) / 100;
    },
};

const discontOptions = [
    { key: "Severed", title: "خصم مقطوعة" },
    { key: "Rate", title: "خصم نسبة" },
];

const defaultAccount = {
    accountTypeId: 0,
    address: "",
    distributor: 0,
    id: 999,
    name: "زبون نقدي",
    phone: "",
};
let init = false;
let canSend = true;
const BillDetailsFooter = ({ Owl }) => {
    const addonsRef = React.useRef(null);
    const [isSaleBill, setIsSaleBill] = React.useState(billType.isSaleBill);
    const doneButtonTitle = React.useMemo(
        () =>
            isSaleBill
                ? Language[SettingsOptions.PrintBillOnSale.value === "Active" ? "PrintAndSave" : "Send"]
                : Language[SettingsOptions.PrintBillOnReturnSale.value === "Active" ? "ReturnSaleAndPrint" : "ReturnSale"],
        [isSaleBill]
    );
    const sendBillRequest = () => {
        // const data = { AllowedQuantity: 10, ItemId: 10, Message: "Negative quantity is not allowed.", StoreId: 1 };
        // Owl.UpdateQuantites(data);
        // Info({ title: "Error", content: "QunatityUnAvailable", type: "error", timeout: 4000 });
        // return;
        console.log(Owl.billItems.billFinancial1.discount, "//", discountValue);
        if (Object.keys(Owl.billItems).length < 2 || !canSend) return;
        console.log("sendBillRequest");
        const user = JSON.parse(localStorage.getItem("logged-user")) || {};
        let billFinancial1 = Owl.billItems.billFinancial1;
        const { note, total, currencyId, discount, discountType, paid, net, distributorId } = billFinancial1;
        let account = billFinancial1.account;
        console.log({ account });
        if (account.id === 999 && account.id !== 0) {
            const searchInput = document.getElementById("owl-search-input");
            let accountName = searchInput.value;
            if (accountName !== "زبون نقدي") {
                global.setPopup({ page: <AddAccount accountName={accountName} sendBill={true} /> });
                return;
            }
        }

        console.log({ billFinancial1 });
        delete Owl.billItems.billFinancial1;

        let items = Object.values(Owl.billItems).reduce((acc, item) => {
            let _item = {
                currencyId: item.currencyId,
                discountNumber: item.discountNumber || 0,
                fillingId: item.fillingId || 0,
                id: parseInt(item.id.split("-")[0]),
                note: item.note || "",
                number: item.billQuantity,
                priceType: "SalePrice",
                singlePrice: item.price,
                storeId: item.storeId,
            };
            acc.push(_item);
            return acc;
        }, []);

        const billToPost = {
            isSaleBill,
            boxId: user.boxId || 1066,
            distributorId: distributorId || 0,
            furnishId: 0,
            providerId: 0,
            storeId: 0,
            tax: 0,
            userId: user.id || 2,
            account: account || defaultAccount,
            note: note || "",
            items,
            billFinancial1: {
                discount,
                currencyId,
                paid: account && account.id !== 999 ? paid : discount > 0 ? net : total,
                discountType,
            },
        };

        console.log(billToPost);
        let _items = [];

        Object.values(Owl.billItems).forEach((item) => {
            _items.push({
                name: item.name,
                billQuantity: item.billQuantity,
                price: Formatter(item.price, item.currencyId),
                total: Formatter(item.price * item.billQuantity, item.currencyId),
                // storeName: stores[item.storeId] || "_",
            });
        });
        if (_items.length < 1) return;

        Owl.billItems.billFinancial1 = billFinancial1;
        global.setPageState(["progressing"]);
        API.post("", billToPost)
            .then(({ data }) => {
                Info({ title: "Sended", content: "", timeout: 2000, type: "success" });
                const user = JSON.parse(localStorage.getItem("logged-user")) || { name: Language.TheUser };
                localStorage.setItem(
                    "print-data",
                    JSON.stringify({
                        billDetails: {
                            billNumber: data.opId2,
                            billFinancial1: { ...billToPost.billFinancial1, total, net },
                            user: user,
                            account: data.account,
                            header: data.isSaleBill ? "SaleBill" : "ReturnBill",
                        },
                        billItems: _items,
                    })
                );
                let print = data.isSaleBill ? SettingsOptions.PrintBillOnSale.value === "Active" : SettingsOptions.PrintBillOnReturnSale.value === "Active";
                if (print) window.open("/print", "Print", "width=auto,height=auto");

                Owl.UpdateQuantites();
                global.setPageState(["none"]);
            })
            .catch((error) => {
                if (error.code !== "ERR_NETWORK") {
                    let data = error.response?.data;
                    if (data && data.Message === "Negative quantity is not allowed.") {
                        Owl.UpdateQuantites(data);

                        Info({ title: "Error", content: "QunatityUnAvailable", type: "error", timeout: 4000 });
                        global.setPageState(["none"]);
                    } else global.setPageState(["error", error]);
                } else global.setPageState(["none"]);
            });
    };

    React.useEffect(() => {
        if (init) return;
        init = true;

        setTimeout(() => {
            if (Owl.billItems?.billFinancial1?.discount)
                discountValue = { option: Owl.billItems.billFinancial1.discountType, value: Owl.billItems.billFinancial1.discount };
            if (Owl.billItems.billFinancial1?.note) notes[0] = Owl.billItems.billFinancial1.note;

            // console.log({ billFinancial1: Owl.billItems.billFinancial1 });

            Owl.addonsContainer = new SeparatedAddons({
                type: "StringOptions",
                containerId: "discount-container",
                buttonClass: "text-center this-button",
                addonsRef: addonsRef,
                onChange: onDiscountChanged,
                cancelTitle: Language.CencelTheDiscont,
                title: Language.AddDiscount,
                options: discontOptions,
                value: discountValue,
                inputProps: { type: "number", pattern: "d+", min: "0.01", step: "0.01" },
                onAddClicked: (val) => {
                    canSend = val;
                },
                // onkeypress="return event.charCode >= 48"
            });
        }, 100);
    }, []);

    function onDiscountChanged(discount) {
        console.log({ discountArray: discount });
        if (discount && !discount.value) discount = null;
        let _discount = discount || { option: "Severed", value: 0 };

        canSend = false;
        if (_discount.option === "Severed") {
            if (_discount.value > Owl.billItems.billFinancial1.total) {
                Info({ title: "خطأ في الخصم", content: "الخصم اكبر من المجموع", type: "error" });
                return false;
            } else if (_discount.value < 0) {
                Info({ title: "خطأ في الخصم", content: "الخصم اقل من 0", type: "error" });
                return false;
            }
        } else {
            if (_discount.value > 100) {
                Info({ title: "خطأ في الخصم", content: "الخصم اكبر من 100%", type: "error" });
                return false;
            } else if (_discount.value < 0) {
                Info({ title: "خطأ في الخصم", content: "الخصم اقل من 0%", type: "error" });
                return false;
            }
        }

        canSend = true;
        Owl.billItems.billFinancial1.discount = _discount.value;
        Owl.billItems.billFinancial1.discountType = _discount.option;

        Owl.updateBillFinancial();
        global.billItemChanged(discount, "UpdateDiscount");
        return true;
    }
    function onNoteChanged() {
        Owl.billItems.billFinancial1.note = notes[0] || "";
        global.billItemChanged(null, "None");
    }

    return (
        <div className="p-2 gap-3 col " style={{ borderTop: " solid 1px #D1D5DB " }}>
            <Finaciall Owl={Owl} />
            {/* <AddButton title={Language.Note} values={notes} cancelTitle={Language.CencelTheNote} buttonClass="pointer px-1" onChange={onNoteChanged}> </AddButton> */}
            <div id="discount-container" className="wrap gap-3 items-start">
                <IconButton
                    extra="text-center"
                    onClick={() => {
                        if (Owl.billItems.billFinancial1.note !== null) return;
                        Owl.billItems.billFinancial1.note = "";
                        Owl.updateBillFinancial();
                    }}
                />
                <Button id="send-bill-button" extra={isSaleBill ? "" : "active"} onClick={sendBillRequest} title={doneButtonTitle} />

                <BooleanText
                    prop={{
                        value: isSaleBill,
                        queryChanged: () => {
                            billType.isSaleBill = !billType.isSaleBill;
                            setIsSaleBill(billType.isSaleBill);
                        },
                        key: "isSaleBill",
                        storageKey: "isSaleBill",
                        title: isSaleBill ? Language.Sale : Language.Return,
                    }}
                />
            </div>
        </div>
    );
};

export default BillDetailsFooter;
