import React from "react";
const SearchableOptions = ({ prop, type = "text", className }) => {
    return (
        <div id="owl-search-input-container" className="pointer row-center">
            <input
                onChange={({ target }) => {
                    checkChange(target, prop);
                }}
                type={type}
                id="owl-search-input"
                className={"owl-search-input " + className}
                defaultValue={prop.value}
                placeholder={prop.title}
            />
            <span id="owl-search-toggle">▶</span>
        </div>
    );
};
export default SearchableOptions;

const checkChange = (target, prop) => {
    let pastValue = target.value;
    setTimeout(() => {
        if (pastValue !== target.value) return;
        {
            prop.value = target.value;
            prop.queryChanged(prop);
        }
    }, 700);
};
