import React from "react";

const ButtonsOptions = ({ prop }) => {
    console.debug("OptionsButtons RD");
    const _class = getClass(prop);
    const ref = React.useRef(null);

    const onClick = (e, value) => {
        prop.value = value;
        ref.current.childNodes.forEach((child) => {
            child.id = "";
        });
        e.currentTarget.id = "active";
        if (prop.storageKey) localStorage.setItem(prop.storageKey, value);
        prop.queryChanged();
    };

    return (
        <div
            ref={ref}
            onWheel={onWheel}
            onMouseDown={(e) => {
                firstX = e.clientX;
            }}
            onMouseUp={(e) => {
                firstX = 0;
                draged = false;
            }}
            onMouseMove={onDrag}
            key={prop.key}
            defaultValue={prop.value}
            className={_class}>
            {prop.options.map((item) => (
                <button
                    id={prop.value == item.id ? "active" : ""}
                    onClick={(e) => onClick(e, item.id)}
                    className={`owl-button-options ${item.className}`}
                    value={item.id}
                    key={item.id}>
                    {item.title}
                </button>
            ))}
        </div>
    );
};

export default ButtonsOptions;

const getClass = (prop) => "owl-options-buttons-container " + (prop.className || "");

let wheeled = false;
const onWheel = (e) => {
    if (wheeled) return;
    wheeled = true;
    setTimeout(() => {
        wheeled = false;
    }, 200);

    console.debug(e.currentTarget.clientWidth);
    let left = (e.currentTarget.clientWidth * e.deltaY) / 110;
    e.currentTarget.scrollBy({
        top: 0,
        left,
        behavior: "smooth",
    });
};
let firstX = 0;
let draged = false;
const onDrag = (e) => {
    if (firstX == 0 || draged) return;
    draged = true;

    let left = (e.currentTarget.clientWidth * 100) / 110;
    if (e.clientX - firstX > 10) {
        e.currentTarget.scrollBy({
            top: 0,
            left: -left,
            behavior: "smooth",
        });
    } else if (e.clientX - firstX < -10) {
        e.currentTarget.scrollBy({
            top: 0,
            left,
            behavior: "smooth",
        });
    } else
        setTimeout(() => {
            draged = false;
        }, 50);

    // console.debug(e.currentTarget.clientWidth);
    // let left = (e.currentTarget.clientWidth * e.deltaY) / 110;
    // e.currentTarget.scrollBy({
    //     top: 0,
    //     left,
    //     behavior: "smooth",
    // });
};
