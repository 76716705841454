import QueryMethods from "../Utils/QueryMethods";
import UrlsGenerater from "../Utils/UrlGenertor";

const OwlSearchOptionsMethods = {
    getOwl: (Owl, { url, api, onResult, onError, queries, useCash, firstLaunch }) => {
        if (Owl.init) return;
        Owl.init = true;

        console.debug("F**king simple should not repate", Owl);

        firstLaunch && firstLaunch(Owl);
        intialProps(url, api, Owl, onResult, onError);
        listnToScrol(Owl);
        SetOnFetch(Owl);
        SetOnSearch(Owl);

        let defaultQP = {};
        if (queries) defaultQP = QueryMethods.Init(queries, defaultQP);

        Initialiser(Owl, defaultQP);
    },
};

const Initialiser = (Owl, defaultQP) => {
    Owl.queryParams = defaultQP;
};

export default OwlSearchOptionsMethods;

//* Validation
const SetRequestId = (Owl) => {
    let requestId = Math.random();
    Owl.requestId = requestId;
    return requestId;
};
const ValidRequest = (Owl, requestId) => Owl.requestId === requestId;

//*---

function SetOnFetch(Owl) {
    Owl.fetch = async (method = "get") => {
        global.setPageState(["fetching"]);
        Owl.canLoadMore = false;
        let requestId = SetRequestId(Owl);
        let query = Owl.query + `&offset=${Owl.offset}`;
        try {
            const { data } = await Owl.api[method](query);
            if (ValidRequest(requestId)) SetOnResult(Owl, data);
            else console.error("Invalid request");
        } catch (error) {
            SetOnError(Owl, error);
        }
    };
}

function SetOnSearch(Owl) {
    Owl.search = async (method = "get") => {
        Owl.offset = 0;
        Owl.limit = Owl.queryParams?.limit?.value || 25;
        Owl.canLoadMore = false;

        Owl.query = UrlsGenerater(Owl.queryParams, Owl.url);
        if (Owl.useCash) {
            let cashItems = JSON.parse(localStorage.getItem(Owl.query)) || [];
            if (cashItems.length > 0) {
                setTimeout(() => {
                    Owl.canLoadMore = true;
                }, 100);
                global.setPageState(["none"]);
                Owl.setItems(cashItems, true);
                return;
            }
        }
        global.setPageState(["searching"]);
        let requestId = SetRequestId(Owl);
        try {
            const { data } = await Owl.api[method](Owl.query);
            if (ValidRequest(requestId)) SetOnResult(Owl, data);
            else console.error("Invalid request");
        } catch (error) {
            SetOnError(Owl, error);
        }
    };
}
const getType = (obj) => Object.prototype.toString.call(obj).slice(8, -1);

const SetOnResult = async (Owl, data) => {
    if (Owl.onResult) data = await Owl.onResult(data);
    let items = [];
    let _data = {};
    if (getType(data) === "Object") {
        Object.entries(data).forEach(([key, value]) => {
            if (Array.isArray(value)) items = value;
            else _data[key] = value;
        });
    } else items = data.length > 0 ? data : [];
    Owl.setItems(items, Owl.offset === 0);
    if (Owl.useCash)
        if (Owl.offset === 0) {
            let allCashQueries = JSON.parse(localStorage.getItem("all-search-cash-queries")) || [];
            !allCashQueries.includes(Owl.query) && allCashQueries.push(Owl.query);
            localStorage.setItem("all-search-cash-queries", JSON.stringify(allCashQueries));
            localStorage.setItem(Owl.query, JSON.stringify(items));
        } else {
            let oldItems = JSON.parse(localStorage.getItem(Owl.query)) || [];
            localStorage.setItem(Owl.query, JSON.stringify([...oldItems, ...items]));
        }

    Owl.offset += items.length;
    Owl.canLoadMore = items.length >= Owl.limit;
    // Owl.set("header", {
    //     length: items.length,
    //     limit: Owl.limit,
    // });
    global.setPageState([items.length > 0 ? "none" : "noData"]);
};

function SetOnError(Owl, error) {
    console.debug({ error });
    Owl.onError && Owl.onError(error);
    if (error.response && error.response.status) {
        let status = error.response.status;
        let main = {
            code: error.code,
            name: error.name,
            message: error.message,
            status: { [status]: error.response.data.title },
            config_: { baseURL: error.config.baseURL },
        };
        let header = error.config.headers;
        error = { ...main, header, ...error };
        delete error.config;
        delete error.response;
    } else if (error.stack) {
        error = { message: error.message, stack: error.stack };
    }
    global.setPageState(["error", error]);
}

function intialProps(url, api, Owl, onResult, onError) {
    Owl.url = url;
    Owl.api = api;
    Owl.onResult = onResult;
    Owl.onError = onError;
    Owl.canLoadMore = true;
    Owl.setQueryParams = function (query) {
        Owl.queryParams = query;
    };
    Owl.offset = 0;
    Owl.items = [];
    Owl.requestId = 13;
}

const listnToScrol = (Owl) => {
    Owl.onScroll = ({ target }) => {
        if (Owl.canLoadMore && target.scrollHeight - target.scrollTop < target.clientHeight + 100) Owl.fetch();
    };
};
