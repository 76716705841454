import React from "react";
import Utils from "../Sale/Utils/Utils";

const GridNodeContainer = ({ Owl, ItemBuilder, className }) => {
    console.debug("Items Node Container RD");
    console.log("Items Node Container RD");

    React.useMemo(() => {
        Owl.setItems = (items, clear) => {
            const itemsContainer = document.getElementById("items-container");
            if (clear) {
                Owl.items = {};
                Utils.removeAllChildNodes(itemsContainer);
            }
            items.map((item) => {
                let billItem = Owl.billItems[item.id];
                if (billItem) item = billItem;
                Owl.items[item.id] = item;
                itemsContainer.append(ItemBuilder({ item, Owl }));
            });
        };
        document.updateItem = (item) => {
            Owl.items[item.id] = item;
            document.getElementById(item.id).replaceWith(ItemBuilder({ item, Owl }));
        };
    }, []);

    return <div id="items-container" className={className} />;
};

export default React.memo(GridNodeContainer);
