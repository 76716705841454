import React from "react";

const PopupBuilder = ({ Owl }) => {
    [global.popup, global.setPopup] = React.useState(null);

    return global.popup ? (
        <div
            id="close-popup"
            onClick={({ target }) => {
                if (target.id.startsWith("close")) {
                    global.popup.close && global.popup.close();
                    global.setPopup(null);
                }
            }}
            className="popup-container">
            <div className="popup-container-2">{global.popup.page}</div>
        </div>
    ) : (
        <></>
    );
};

export default PopupBuilder;
