import React from "react";

const SearchingIcon = () => (
    <div className="owl-loading " />
    // <div className="searching-animation">
    //     <div className="searching-part-conatiner">
    //         <div className="searching-icon-tail" />
    //         <div className="searching-icon">
    //             <div className="clip-shin" />
    //         </div>
    //         <div />
    //     </div>
    // </div>
);

export default SearchingIcon;
