import Info from "../../Nodes/Info";
import { P } from "../../Nodes/NodeBuilder";
import { UpdateItemsNodes } from "../Items/ItemNode";
import { AddNewBillsItem, AddItemDetailsEvent } from "../ItemsDetails/ItemDetailsNode";
import { billType, SettingsOptions } from "./Consts";

const ItemsSyncMethods = {
    SetupStorageMethods,
    AddItemDetailsEvent,
};
export default ItemsSyncMethods;

function SetupStorageMethods(Owl) {
    if (Owl.switchBills) return;
    addBillSwitchedEvent(Owl);
    storeTheBillToStorage(Owl);
    syncBillsToStorage(Owl);

    listnToBillItemChange(Owl);
    Owl.UpdateQuantites = (data) => UpdateQuantites(Owl, data);
}

function syncBillsToStorage(Owl) {
    Owl.syncBillsToStorage = function () {
        console.debug("syncBillsToStorage");
        localStorage.setItem(Owl.billsConatiner.billId, JSON.stringify(Owl.billItems));
    };
}

function storeTheBillToStorage(Owl) {
    Owl.storTheBills = function (storageRequesId = Math.random()) {
        Owl.storageRequesId = storageRequesId;
        setTimeout(() => {
            if (Owl.storageRequesId === storageRequesId) {
                Owl.syncBillsToStorage();
            }
        }, 1000);
    };
}

function addBillSwitchedEvent(Owl) {
    Owl.switchBills = function (billId, sync = true) {
        if (sync) {
            Owl.storageRequesId = Math.random();
            Owl.syncBillsToStorage();
        }
        Owl.billItems = JSON.parse(localStorage.getItem(billId)) || {
            billFinancial1: {
                account: null,
                total: 0,
                discount: 0,
                discountType: "Severed",
                note: null,
                paid: 0,
                currencyId: parseInt(localStorage.getItem("TheCurrency") || 1),
                distributorId: 0,
            },
        };
        global.accountChanged(Owl.billItems.billFinancial1.account);
        if (Owl.addonsContainer) {
            Owl.addonsContainer.setValue(Owl.billItems.billFinancial1.discount);
        }
        if (Owl.setDistributorId) Owl.setDistributorId(Owl.billItems.billFinancial1.distributorId);

        UpdateItemsNodes(Owl);
        AddNewBillsItem(Owl, true);
    };
}

function listnToBillItemChange(Owl) {
    global.billItemChanged = function (item, action) {
        ItemChangeActions[action](item, Owl);
        Owl.updateBillFinancial();
        item && checkNotAllowedQuantity(item);
    };
}

const ItemChangeActions = {
    AddQuantity,
    ReduceQuantity,
    DeleteBillItem,
    UpdateTotal,
    itemQuantity,
    None: () => {},
    UpdateDiscount,
    UpdateAccount,
    UpdateDistributor,
    BillFinancial1: () => {},
};

export function checkNotAllowedQuantity(item) {
    let extraQuantity = item.quantity - item.billQuantity;
    let billNotAllowedQuantityNode = document.getElementById(`${item.billId}-not-allowed-quantity`);
    if (billType.isSaleBill && item.preventNegative && extraQuantity < 0) {
        item.extraQuantity = extraQuantity;
        if (billNotAllowedQuantityNode) {
            billNotAllowedQuantityNode.innerText = item.extraQuantity;
        } else {
            document
                .getElementById(`${item.billId}`)
                .append(P({ id: `${item.billId}-not-allowed-quantity`, className: "not-allowed-quantity", innerText: item.extraQuantity }));
        }
    } else if (billNotAllowedQuantityNode) billNotAllowedQuantityNode.remove();
}

function UpdateTotal(item, Owl) {
    Owl.billItems.billFinancial1.total += item.price;
}

function UpdateAccount(account, Owl) {
    Owl.billItems.billFinancial1["account"] = account;
}
function UpdateDistributor(distributorId, Owl) {
    Owl.billItems.billFinancial1["distributorId"] = distributorId;
}

function UpdateDiscount(discount, Owl) {
    if (!discount) return;
    Owl.billItems.billFinancial1.discountType = discount.option;
    Owl.billItems.billFinancial1.discount = Math.round(parseFloat(discount.value) * 100) / 100;
    Owl.billItems.billFinancial1.net = Owl.billItems.billFinancial1.total - Owl.billItems.billFinancial1.discount;
}

function AddQuantity(item, Owl) {
    item.billQuantity++;
    Owl.billItems.billFinancial1.total += item.price;
    Owl.billItems[item.id] = item;
    document.updateItem(item);
}

function itemQuantity(item, Owl) {
    let netQuantity = item.newQuantity - item.billQuantity;

    Owl.billItems.billFinancial1.total += netQuantity * item.price;
    item.billQuantity = item.newQuantity;

    delete item.newQuantity;
    Owl.billItems[item.id] = item;
    document.updateItem(item);
}

function ReduceQuantity(item, Owl) {
    item.billQuantity--;
    Owl.billItems.billFinancial1.total -= item.price;
    Owl.billItems[item.id] = item;
    document.updateItem(item);
}

function DeleteBillItem(item, Owl) {
    Owl.billItems.billFinancial1.total -= item.price * item.billQuantity;
    item.billQuantity = 0;
    document.updateItem(item);
    document.getElementById(item.billId).remove();
    delete Owl.billItems[item.id];
}

export function CheckSaleInNagative(value, item) {
    if (billType.isSaleBill && value > item.quantity) {
        if (item.preventNegative) {
            Info({ title: "CannotSaleInNegative", content: "ItemQuantityLessThenQuantityInStore", type: "error" });
            return true;
        } else if (SettingsOptions.ShowNotificationOnSaleInNegative.value === "Show")
            Info({ title: "SaleInNegativeWarnning", content: "ItemQuantityLessThenQuantityInStore", type: "warn" });
    }
}

function UpdateQuantites(Owl, data) {
    if (data) {
        // AllowedQuantity: 15
        // ItemId: 21
        // Message: "Negative quantity is not allowed."
        // StoreId: 1
        let id = `${data.ItemId}-${data.StoreId}`;

        const foundedItem = Owl.items[id];
        if (foundedItem) {
            foundedItem.quantity = data.AllowedQuantity;
        }

        let allCashedItems = JSON.parse(localStorage.getItem("all-cash-queries")) || [];

        allCashedItems.forEach((item) => {
            let storedItems = JSON.parse(localStorage.getItem(item)) || [];
            localStorage.setItem(
                item,
                JSON.stringify(
                    storedItems.map((storedItem) => {
                        if (storedItem.id === id) storedItem.quantity = data.AllowedQuantity;
                        return storedItem;
                    })
                )
            );
        });
        global.billItemChanged(foundedItem, "None");
    } else {
        let allQuantites = JSON.parse(localStorage.getItem("all-cash-queries")) || [];

        billType.isSaleBill
            ? Object.values(Owl.billItems).forEach((item) => {
                  const foundedItem = Owl.items[item.id];
                  item.quantity -= item.billQuantity;
                  if (foundedItem) foundedItem.quantity = item.quantity;
              })
            : Object.values(Owl.billItems).forEach((item) => {
                  const foundedItem = Owl.items[item.id];
                  item.quantity += item.billQuantity;
                  if (foundedItem) foundedItem.quantity = item.quantity;
              });

        allQuantites.forEach((item) => {
            let storedItems = JSON.parse(localStorage.getItem(item)) || [];
            localStorage.setItem(
                item,
                JSON.stringify(
                    storedItems.map((storedItem) => {
                        const billItem = Owl.billItems[storedItem.id];
                        if (billItem) storedItem.quantity = billItem.quantity;
                        return storedItem;
                    })
                )
            );
        });
        Owl.remvoeBill();
        Owl.updateBillFinancial();
    }
}
