import Info from "../../Nodes/Info";
import { billType, SettingsOptions } from "../Utils/Consts";

const ItemNode = ({ item, Owl }) => {
    const container = createItemContainer(item, Owl);

    container.appendChild(createNameP(item));
    if (item.packages) container.appendChild(createPackContainer(item));
    else container.appendChild(createBillQuantitySpan(item));
    return container;
};

export default ItemNode;

const updateCardState = (state, container) => {
    if (container.localName !== state) container.setAttribute("localName", state);
};
function createItemContainer(item, Owl) {
    const container = document.createElement("div");
    container.className = "item-builder owl-count-holder text-center relative";

    const onclick = (e) => {
        if (item.packages) {
            updatePack(e, item, container);
        } else {
            if (billType.isSaleBill && item.quantity - item.billQuantity <= 0) {
                if (item.preventNegative) {
                    setCardStateError(container);
                    return;
                }
                setCardStateWarn(container);
            } else updateCardState("selected", container);

            container.querySelector(".state-target").innerText = ++item.billQuantity;
            Owl.addOrUpdateItemDetails(item);
        }
    };

    if (item.billQuantity)
        updateCardState(billType.isSaleBill && item.billQuantity > item.quantity ? (item.preventNegative ? "error" : "warn") : "selected", container);

    container.id = item.id;
    container.onclick = onclick;
    return container;
}

function updatePack(e, item, container) {
    let packageId = e.target.packageId;
    if (!packageId) return;
    item.packageId = packageId;
    // let selectdPack = container.querySelector(".selected");
    // if (item.packageId !== packageId) {
    //     item.packageId = packageId;
    //     selectdPack.classList.remove("selected");
    //     e.target.classList.add("selected");
    //     return;
    // }
    const _pack = item.packages.find((pack) => pack.id === item.packageId);
    if (billType.isSaleBill && _pack.quantity - _pack.billQuantity <= 0) {
        if (item.preventNegative) {
            setCardStateError(container);
            return;
        }
        setCardStateWarn(container);
    } else updateCardState("selected", container);
    _pack.billQuantity++;
    e.target.innerText = _pack.billQuantity > 0 ? `${_pack.name}\n${_pack.billQuantity}` : _pack.name;
}

function setCardStateWarn(container) {
    if (SettingsOptions.ShowNotificationOnSaleInNegative.value === "Show")
        Info({ title: "SaleInNegativeWarnning", content: "ItemQuantityLessThenQuantityInStore", type: "warn" });
    updateCardState("warn", container);
}

function setCardStateError(container) {
    Info({ title: "CannotSaleInNegative", content: "ItemQuantityLessThenQuantityInStore", type: "error" });
    updateCardState("error", container);
}

function createNameP(item) {
    const name = document.createElement("p");
    name.innerText = item.name;
    return name;
}

function createBillQuantitySpan(item) {
    const billQuantity = document.createElement("span");
    billQuantity.className = "owl-count state-target";
    billQuantity.innerText = item.billQuantity || "";
    return billQuantity;
}
function createPackContainer(item) {
    const packages = document.createElement("p");
    packages.className = "packages-container";
    item.packages.forEach((_pack) => {
        const pack = document.createElement("span");
        pack.className = "package";
        // pack.classList.add(_pack.state);
        pack.innerText = _pack.billQuantity > 0 ? `${_pack.name}\n${_pack.billQuantity}` : _pack.name;
        pack.packageId = _pack.id;
        packages.append(pack);
    });
    return packages;
}

export const UpdateItemsNodes = (Owl) => {
    Object.keys(Owl.items).forEach((id) => {
        const item = Owl.items[id];
        let itemNode = document.getElementById(id);
        let billItem = Owl.billItems[id];
        if (billItem) {
            item.billQuantity = billItem.billQuantity;
            handleUpdateItemState(item, itemNode);
            itemNode.children[1].innerText = item.billQuantity;
        } else if (item.billQuantity) {
            item.billQuantity = 0;
            itemNode.setAttribute("localName", "");
            itemNode.children[1].innerText = "";
        }
    });
};

function handleUpdateItemState(item, child) {
    let state = billType.isSaleBill && item.billQuantity > item.quantity ? (item.preventNegative ? "error" : "warn") : "selected";
    if (child.localName !== item.state) child.setAttribute("localName", state);
}
