import Language from "./Language";
export const Version = "v 1.3.2";
const currecnies = [
    { title: "1", id: 1 },
    { title: "2", id: 2 },
];
const limits = [
    { title: "25", id: 25 },
    { title: "50", id: 50 },
];
const cols = [
    //
    { key: "id", initialWidthFactor: 0.6, title: "الرقم" },
    { key: "price", initialWidthFactor: 0.4, title: "السعر" },
];

const Consts = {
    spliter: {
        cols: [0.6, 0.4],
        className: "split-container",
        storageKey: "split-colums",
    },
    queries: [
        {
            className: "scroller owl-query-section px-4",
            children: [
                { key: "currencyId", type: "None", title: "العملة", value: localStorage.getItem("TheCurrency") || 1, options: currecnies },
                { key: "limit", type: "None", title: "", value: 50, options: limits },
                // { key: "touchonly", type: "None", title: "", value: true },
                {
                    key: "query",
                    type: "String",
                    title: Language.SearchingFor,
                    value: "",
                    containerClass: "flex-grow col relative",
                    showInClearBar: false,
                },
                JSON.parse(
                    localStorage.getItem("inventory-list") ||
                        JSON.stringify({ key: "storeId", type: "Options", value: -1, options: [{ title: "كل المتاجر", id: -1 }] })
                ),
                JSON.parse(
                    localStorage.getItem("groups-list") ||
                        JSON.stringify({ key: "groupId", type: "ButtonsOptions", value: -1, options: [{ title: "الكل", id: -1 }] })
                ),
            ],
        },
    ],
    accountsQueries: [
        {
            className: "",
            children: [
                { key: "UserId", type: "None", title: "user id", value: JSON.parse(localStorage.getItem("logged-user"))?.id },
                { key: "Limit", type: "None", title: "", value: 25, options: limits },
                { key: "Offset", type: "None", title: "", value: 0, options: limits },
                {
                    key: "Query",
                    type: "String",
                    title: "اسم الزبون",
                    containerClass: "flex-grow col relative",
                    showInClearBar: false,
                },
                // JSON.parse(
                //     localStorage.getItem("inventory-list") ||
                //         JSON.stringify({ key: "storeId", type: "Options", value: -1, options: [{ title: "كل المتاجر", id: -1 }] })
                // ),
                // JSON.parse(
                //     localStorage.getItem("groups-list") ||
                //         JSON.stringify({ key: "groupId", type: "ButtonsOptions", value: -1, options: [{ title: "الكل", id: -1 }] })
                // ),
            ],
        },
    ],
};

export const SettingsOptions = {
    PoupKeyborad: {
        name: "PoupKeyborad",
        values: ["Active", "UnActive"],
        value: localStorage.getItem("PoupKeyborad") || "Active",
    },
    // NotificationsOnSaleInNagative: {
    //     name: "NotificationsOnSaleInNagative",
    //     values: ["Active", "UnActive"],
    //     value: localStorage.getItem("NotificationsOnSaleInNagative") || "Active",
    // },
    ShowBillList: {
        name: "ShowBillList",
        values: ["Show", "Hide"],
        value: localStorage.getItem("ShowBillList") || "Show",
    },
    TheCurrency: {
        name: "TheCurrency",
        values: ["1", "2"],
        value: localStorage.getItem("TheCurrency") || "1",
        worrning: "AllBillsWellBeDeleted",
    },
    ShowNotificationOnSaleInNegative: {
        name: "ShowNotificationOnSaleInNegative",
        values: ["Show", "Hide"],
        value: localStorage.getItem("ShowNotificationOnSaleInNegative") || "Show",
    },
    PrintBillOnSale: {
        name: "PrintBillOnSale",
        values: ["Active", "UnActive"],
        value: localStorage.getItem("PrintBillOnSale") || "Active",
    },
    PrintBillOnReturnSale: {
        name: "PrintBillOnReturnSale",
        values: ["Active", "UnActive"],
        value: localStorage.getItem("PrintBillOnReturnSale") || "UnActive",
    },
    // UseCash: {
    //     name: "UseCash",
    //     values: ["Active", "UnActive"],
    //     value: localStorage.getItem("UseCash") || "Active",
    // },
};

export function getStoredSettings() {
    return {
        PoupKeyborad: localStorage.getItem("PoupKeyborad") || "Active",
        ShowBillList: localStorage.getItem("ShowBillList") || "Show",
        TheCurrency: localStorage.getItem("TheCurrency") || "1",
        ShowNotificationOnSaleInNegative: localStorage.getItem("ShowNotificationOnSaleInNegative") || "Show",
        PrintBillOnSale: localStorage.getItem("PrintBillOnSale") || "Active",
        PrintBillOnReturnSale: localStorage.getItem("PrintBillOnReturnSale") || "UnActive",
        // UseCash: localStorage.getItem("UseCash") || "Active",
    };
}

export function uuid() {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
        var r = (Math.random() * 16) | 0,
            v = c == "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
    });
}

export default Consts;

export const CurrenciesTitle = {
    1: "د.ع",
    2: "$",
};

export const billType = {
    isSaleBill: localStorage.getItem("isSaleBill") !== "false",
};
