import React from "react";
import PageStateProvider from "../PageStateKit/PageStateProvider";
import PopupBuilder from "../Popups/PopupBuilder";
import QueryContainer from "../Query/QueryContainer";
import HeaderContainer from "./HeaderContainer";

const OwlItems = ({ queries, queryContainerClass, Owl, queryChanged, header }) => {
    return (
        <>
            {queries && <QueryContainer className={queryContainerClass} Owl={Owl} queryChanged={queryChanged} queries={queries} />}
            <PageStateProvider Owl={Owl} />
            {header && <HeaderContainer Owl={Owl} HeaderBuilder={header} />}
            <PopupBuilder Owl={Owl} />
        </>
    );
};
export default OwlItems;
