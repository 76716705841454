import String from "./AddonsKit/String";
import BottomSheet from "./AddonsKit/BottomSheet";
import StringOptions from "./AddonsKit/StringOptions";

export default (prop) => AddonsKit[prop.type](prop);

const AddonsKit = {
    String,
    BottomSheet,
    StringOptions,
};
