const mainClass = "item-builder owl-count-holder text-center relative";

const mock = {
    accountTypeId: 0,
    address: "النجف الاشرف - حي الغري",
    distributor: 0,
    id: 7210,
    isPermanent: false,
    name: "ضرغام الفياض",
    phone: "07832839726",
};

const AccountsNodeBuilder = ({ item, onClick }) => {
    const name = document.createElement("p");
    name.className = "search-options-label";
    name.innerText = item.name;
    name.id = item.id;
    // name.onclick = () => {
    //     onClick(item);
    // };
    return name;
};

export default AccountsNodeBuilder;
