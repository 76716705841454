import React, { Fragment } from "react";
import Fetching from "./Fetching";
import Loading from "./Loading";
import Error from "./Error";
import Progressing from "./Progressing";
import Searching from "./Searching";
import DefaultNoData from "../Defaults/DefaultNoData";
import JsonToView from "../Defaults/JsonToView";
import NoInternet from "./NoInternet";

const PageStateKit = {
    loading: Loading,
    fetching: Fetching,
    progressing: Progressing,
    error: Error,
    noInternet: NoInternet,
    searching: Searching,
    noData: DefaultNoData,
    none: () => <Fragment />,
    undefined: () => <Fragment />,
    message: ({}) => (
        <div className="left-0 top-0 fixed inset-0 z-50 overflow-auto">
            <JsonToView item={global.pageState[1]} />
        </div>
    ),
};

export default PageStateKit;
