import React from "react";
import PageStateKit from "./PageStateKit";

const PageStateProvider = ({ Owl, pageStateKit = PageStateKit }) => {
    [global.pageState, global.setPageState] = React.useState(["none"]);
    // React.useEffect(() => {
    //     console.log("global.pageState", global.pageState);
    // }, [global.pageState]);
    return React.useMemo(() => {
        const PageState = pageStateKit[global.pageState[0]];
        return <PageState owl={Owl} />;
    }, [global.pageState]);
};

export default PageStateProvider;
