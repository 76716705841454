import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import InputText from "../Components/InputText";
import MorabaaIcon from "../Components/MorabaaIcon";
import { API } from "../SalePointAPI";
import { uuid } from "../Utils/Consts";
import Language from "../Utils/Language";

const LoginRoute = () => {
    const [userName, setUserName] = useState("");
    const [password, setPassword] = useState("");
    let navigate = useNavigate();
    const loginBtnRef = React.useRef(null);

    React.useEffect(() => {
        if (!localStorage.getItem("token")) {
            localStorage.setItem("token", uuid());
            window.location.reload();
        }
    });

    const onClickLogin = async (e) => {
        if (userName === "" || password === "") return;
        if (loginBtnRef.current.classList["loading"]) return;
        loginBtnRef.current.classList.add("loading");
        await API.post("login", { userName, password })
            .then(({ data }) => {
                localStorage.setItem("logged-user", JSON.stringify(data));
                navigate("/");
                setTimeout(() => {
                    window.location.reload();
                }, 10);
            })
            .catch(({ code }) => {
                loginBtnRef.current.classList.remove("loading");
                loginBtnRef.current.classList.add("error");
                loginBtnRef.current.firstChild.innerText = code === "ERR_NETWORK" || code === "ECONNABORTED" ? Language.NetError : Language.UserOrPasswordError;
                setTimeout(() => {
                    loginBtnRef.current.classList.remove("error");
                    loginBtnRef.current.firstChild.innerText = Language.Login;
                }, 1500);
            });
    };

    return (
        <div className="h-screen col overflow-auto p-3">
            <form
                onSubmit={(e) => {
                    e.preventDefault();
                    loginBtnRef.current.click();
                    return false;
                }}
                className="col p-10 gap-5 justify-center mx-auto font-bold bg-white shadow-1 rounded-lg m-auto ">
                <MorabaaIcon />
                <InputText required value={userName} placeholder={Language.UserName} setValue={setUserName} />
                <InputText required placeholder={Language.Password} value={password} setValue={setPassword} type="password" />
                <p type="subimt" ref={loginBtnRef} className="this-button mx-auto bg-primary text-white" onClick={onClickLogin}>
                    <span>{Language.Login}</span>
                    <span id="loader-target" />
                </p>
                <input type="submit" style={{ display: "none" }} onClick={() => loginBtnRef.current.click()} />
            </form>
        </div>
    );
};

export default LoginRoute;
