import UrlsGenerater from "./UrlGenertor";
import QueryMethods from "./QueryMethods";
import getResizebleTableObj from "./ResizebleMethods";
import ResizebleMethodsSplit from "./ResizebleMethodsSplit";
import GetkeyBorad from "../Popups/PopupKeybord";
import Info from "../Nodes/Info";
import { DinarRound } from "./CurrencyFromater";
import { billType } from "../Sale/Utils/Consts";

const OwlMethods = {
    getOwl: (Owl, { url, api, onResult, onError, queries, useCash, spliter, firstLaunch, poupKeyboard }) => {
        if (Owl.init) return;
        Owl.init = true;
        Owl.useCash = useCash;
        firstLaunch && firstLaunch(Owl);

        console.debug("F**king should not repate", Owl);

        intialProps(url, api, Owl, onResult, onError);
        listnToScrol(Owl, poupKeyboard);
        setupSetEvent(Owl);

        SetOnFetch(Owl);
        SetOnSearch(Owl);

        let defaultQP = {};
        if (queries) defaultQP = QueryMethods.Init(queries, defaultQP);
        else defaultQP = false;

        Initialiser(Owl, defaultQP);

        if (!spliter) return;
        Owl.spliter = spliter;
        Owl.ResizebleObj = {};
        if (Owl.spliter.isTable) {
            if (!Owl.spliter.className) Owl.spliter.className = "owl-table";
            getResizebleTableObj(Owl.ResizebleObj, Owl.spliter);
        } else {
            if (!Owl.spliter.className) Owl.spliter.className = "split-container";
            ResizebleMethodsSplit(Owl.ResizebleObj, Owl.spliter);
        }
    },
};

const Initialiser = (Owl, defaultQP) => {
    setTimeout(() => {
        if (!defaultQP) Owl.search();
        else if (Owl.setQueryParams !== false) {
            Owl.queryParams = defaultQP;
            Owl.setQueryParams(defaultQP);
            Owl.search();
        } else Initialiser(Owl, defaultQP);
    }, 10);
};

export default OwlMethods;

//* Validation
const SetRequestId = (Owl) => {
    let requestId = Math.random();
    Owl.requestId = requestId;
    return requestId;
};
const ValidRequest = (Owl, requestId) => Owl.requestId === requestId;

//*---

function SetOnFetch(Owl) {
    Owl.fetch = async (method = "get") => {
        global.setPageState([Owl.fetchAll ? "progressing" : "fetching"]);
        Owl.canLoadMore = false;
        let requestId = SetRequestId(Owl);
        let query = Owl.query + `&offset=${Owl.offset}`;
        try {
            const { data } = await Owl.api[method](query);
            if (ValidRequest(Owl, requestId)) SetOnResult(Owl, data);
            else console.error("Invalid request");
        } catch (error) {
            SetOnError(Owl, error);
        }
    };
}

function SetOnSearch(Owl) {
    Owl.search = async (method = "get") => {
        Owl.offset = 0;
        Owl.limit = Owl.queryParams?.limit?.value || 25;
        Owl.canLoadMore = false;

        Owl.query = UrlsGenerater(Owl.queryParams, Owl.url);
        if (Owl.useCash) {
            let cashItems = JSON.parse(localStorage.getItem(Owl.query)) || [];
            if (cashItems.length > 0) {
                setTimeout(() => {
                    Owl.canLoadMore = true;
                }, 100);
                global.setPageState(["none"]);
                Owl.setItems(cashItems, true);
                Owl.offset = cashItems.length;
                return;
            }
        }
        global.setPageState(["searching"]);

        let requestId = SetRequestId(Owl);
        try {
            const { data } = await Owl.api[method](Owl.query);
            if (ValidRequest(Owl, requestId)) SetOnResult(Owl, data);
            else console.error("Invalid request");
        } catch (error) {
            SetOnError(Owl, error);
        }
    };
}
const getType = (obj) => Object.prototype.toString.call(obj).slice(8, -1);

const SetOnResult = async (Owl, data) => {
    if (Owl.onResult) data = await Owl.onResult(data);
    let items = [];
    let _data = {};
    if (getType(data) === "Object") {
        Object.entries(data).forEach(([key, value]) => {
            if (Array.isArray(value)) items = value;
            else _data[key] = value;
        });
    } else items = data.length > 0 ? data : [];
    Owl.setItems(items, Owl.offset === 0);
    if (Owl.useCash)
        if (Owl.offset === 0) {
            let allCashQueries = JSON.parse(localStorage.getItem("all-cash-queries")) || [];
            !allCashQueries.includes(Owl.query) && allCashQueries.push(Owl.query);
            localStorage.setItem("all-cash-queries", JSON.stringify(allCashQueries));
            localStorage.setItem(Owl.query, JSON.stringify(items));
        } else {
            let oldItems = JSON.parse(localStorage.getItem(Owl.query)) || [];
            localStorage.setItem(Owl.query, JSON.stringify([...oldItems, ...items]));
        }

    Owl.offset += items.length;
    Owl.canLoadMore = items.length == Owl.limit;

    if (Owl.canLoadMore) global.setPageState((state) => (state[0] !== "progressing" ? [items.length > 0 ? "none" : "noData"] : state));
    else global.setPageState(() => [Owl.offset == "0" && items.length < 1 ? "noData" : "none"]);
};

function SetOnError(Owl, error) {
    console.debug({ error });
    Owl.onError && Owl.onError(error);
    if (error.code === "ERR_NETWORK") {
        global.setPageState(["noInternet", error]);
        return;
    } else if (error.response && error.response.status) {
        let status = error.response.status;
        let main = {
            code: error.code,
            name: error.name,
            message: error.message,
            status: { [status]: error.response.data.title },
            config_: { baseURL: error.config.baseURL },
        };
        let header = error.config.headers;
        error = { ...main, header, ...error };
        delete error.config;
        delete error.response;
    } else if (error.stack) {
        error = { message: error.message, stack: error.stack };
    }

    // global.setPageState(["error", error]);
}

function intialProps(url, api, Owl, onResult, onError) {
    Owl.url = url;
    Owl.api = api;
    Owl.onResult = onResult;
    Owl.onError = onError;
    Owl.canLoadMore = true;
    Owl.offset = 0;
    Owl.items = [];
    Owl.queryParams = {};
    Owl.requestId = 13;
    Owl.setQueryParams = false;
    Owl.defaultQueryParams = {};
}

function setupSetEvent(Owl) {
    Owl.set = ({ key, value, event }) => {
        if (event && Owl[event]) {
            Owl[event](value);
            return;
        }
        if (!key) return;
        const keys = key.split(".");
        if (keys[0] === "pageState") global.setPageState([value]);
        else if (keys[0] === "header") {
            if (typeof value === "object") Owl.setHeader((_header) => ({ ..._header, ...value }));
            else Owl.setHeader(value);
        } else if (keys[0] === "item") Owl.setItems((_items) => _items.map((_item) => (_item.id === value.id ? { ..._item, ...value } : _item)));
        else if (keys[0] === "items") Owl.setItems(value);
        else if (keys[0] === "details") {
            if (keys[1]) {
                if (typeof value === "object")
                    Owl.setDetails((_details) => {
                        _details[keys[1]] = { ..._details[keys[1]], value };
                        return _details;
                    });
                else
                    Owl.setDetails((_details) => {
                        _details[keys[1]] = value;
                        return _details;
                    });
            } else {
                if (typeof value === "object") Owl.setDetails((_details) => ({ ..._details, ...value }));
                else Owl.setDetails(value);
            }
        }
    };
}

const listnToScrol = (Owl, poupKeyboard) => {
    // let wheeled = false;
    // Owl.onScroll = (e) => {
    //     if (wheeled) return;
    //     wheeled = true;
    //     setTimeout(() => {
    //         wheeled = false;
    //     }, 200);
    //     const target = e.currentTarget;
    //     console.debug(e.currentTarget.firstChild.firstChild.clientHeight);
    //     let top = (target.clientHeight * e.deltaY) / 100 - e.currentTarget.firstChild.firstChild.clientHeight * 2;
    //     e.currentTarget.scrollBy({
    //         top,
    //         left: 0,
    //         behavior: "smooth",
    //     });
    //     if (Owl.canLoadMore && target.scrollHeight - target.scrollTop < target.clientHeight + 300) {
    //         Owl.fetch();
    //     }
    // };

    if (poupKeyboard) {
        Owl.onFocus = function (e) {
            Owl.focused = true;
            GetkeyBorad(e);
        };
    } else
        Owl.onFocus = () => {
            Owl.focused = true;
        };
    Owl.onBlur = () => {
        Owl.focused = false;
    };

    Owl.barcode = "";
    window.addEventListener("keydown", async (e) => {
        if (Owl.focused) return;
        if (e.key === "Enter") {
            if (Owl.barcode.length < 1) {
                Owl.barcode = "";
                return;
            }
            // Owl.barcode = "5017020182091";
            const { data } = await Owl.api.get(`items?limit=10&offset=0&query=${Owl.barcode}&currencyId${localStorage.getItem("TheCurrency") || 1}`);
            console.log(data);
            if (data.length > 0) {
                let item = data[0];
                item.id = `${item.id}-${item.storeId}`;
                let foundedItem = Owl.billItems[item.id];
                item.billQuantity = foundedItem ? foundedItem.billQuantity + 1 : 1;

                if (billType.isSaleBill && item.quantity - item.billQuantity <= 0) {
                    if (item.preventNegative) {
                        Info({ title: "CannotSaleInNegative", content: "ItemQuantityLessThenQuantityInStore", type: "error" });
                        Owl.barcode = "";
                        return;
                    }
                    Info({ title: "SaleInNegativeWarnning", content: "ItemQuantityLessThenQuantityInStore", type: "warn" });
                }

                if (localStorage.getItem("TheCurrency") != "2") {
                    item.price = DinarRound(item.price);
                }
                Owl.addOrUpdateItemDetails(item);
            } else Info({ title: "NotFound", content: "ItemNotFound", type: "error" });
            Owl.barcode = "";
        } else Owl.barcode += e.key;
    });

    Owl.onScroll = ({ target }) => {
        //Owl.set("header", { canLoadMore: Owl.canLoadMore, scrollHeight: target.scrollHeight, scrollTop: target.scrollTop, target_clientHeight: target.clientHeight + 100, scrollTop____target_clientHeight: target.scrollTop < target.clientHeight + 100,});
        if (Owl.canLoadMore && target.scrollHeight - target.scrollTop < target.clientHeight + 100) {
            Owl.fetch();
        }
    };
};
