import axios from "axios";
import Info from "../Nodes/Info";
const host = window.location.host;
// me 198
// dr 214
// const OnlineStoreRoot = host === "localhost:33133" || host.includes("pages.dev") ? "https://smooth-dove.morabaa.cloud/api/v1/pos" : "/api/v1/pos";
// const OnlineStoreRoot = "http://192.168.120.214:7777/api/v1/pos";
// const OnlineStoreRoot = "http://192.168.68.201:7777/api/v1/pos";
// const OnlineStoreRoot = "http://192.168.69.166:9999/api/v1/pos";
const OnlineStoreRoot = "https://smooth-dove.morabaa.cloud/api/v1/pos";

const create = () => {
    let instance = axios.create({
        baseURL: OnlineStoreRoot,
        headers: {
            accept: "text/plain",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            token: localStorage.getItem("token"),
        },
    });
    instance.interceptors.response.use(
        (response) => {
            return response;
        },
        (error) => {
            console.log({ "error.response.code": error.code });
            if (!error.response) {
            }
            if (error.status === 401) {
                localStorage.removeItem("token");
                window.location.href = "/login";
            } else if (error.code === "ERR_NETWORK") Info({ title: "InternetProplem", content: "NoInternet", type: "error", timeout: -1 });
            return Promise.reject(error);
        }
    );
    return instance;
};

export const API = create();

const mock = {
    isSaleBill: true, //true sale & false return sale
    storeId: 0,
    boxId: 0, //box id from login user
    note: "string",
    tax: 0, //not
    billFinancial1: {
        discountType: "Severed", // Severed مقطوعة  & rate نسبة
        paid: 0, // الواصل
        currencyId: 0,
        discount: 0,
    },
    billFinancial2: {
        discountType: "Severed",
        paid: 0,
        currencyId: 0,
        discount: 0,
    },
    account: {
        // new just the down
        id: 0, // new customer id =0
        accountTypeId: 0, // default 0
        // new just the up

        name: "string",
        phone: "string",
        address: "string",
        distributor: 0,
    },
    userId: 0, //loged user
    opId2: 0, //not
    items: [
        {
            id: 0,
            currencyId: 0,
            date: "2022-08-04T11:03:01.157Z", //first intial
            fillingId: 0, //not
            number: 0, //bill quntity
            singlePrice: 0, //item price
            storeId: 0, //store id uniq
            priceType: "SalePrice", // not
            note: "string",
            note2: "string",
            discountNumber: 0, //not
        },
    ],
};
