import React from "react";
const OwlScroller = ({ Owl, children }) => {
    console.debug("Scroller RD");
    return (
        <div id="owl-scroller" onScroll={Owl.onScroll} className="overflow-auto scroller p-3 bg rounded-xl bg-white " style={{}}>
            {children}
        </div>
    );
};

export default OwlScroller;
